import { isEmpty } from '@ember/utils';
import type { LineSeries, LineSeriesCollection } from '../types.ts';

/**
 * Generates two line series from a given line in the format { pointLine,
 * solidLine }. This splits the source line along orphaned points so they can be
 * visible while main line is smooth / point-less.
 *
 * @function buildSeriesLines
 * @param {LineSeries} sourceLine Initial line series.
 * @param {T} hideMarkerOptions Line options with markers off.
 * @param {T} showMarkerOptions Line options with markers on.
 */
export function buildSeriesLines<T extends Highcharts.SeriesLineOptions>(
  sourceLine: LineSeries,
  hideMarkerOptions: T,
  showMarkerOptions: T,
): LineSeriesCollection<T> {
  // Clone the series.
  let solidLine: (T & LineSeries) | null = Object.assign(
    hideMarkerOptions,
    sourceLine,
  );
  let pointLine: (T & LineSeries) | null = Object.assign(
    showMarkerOptions,
    sourceLine,
  );

  // Clear series for rebuild.
  pointLine.data = [];
  solidLine.data = [];

  if (sourceLine.data.length > 1) {
    sourceLine.data.forEach((element, index) => {
      let pushPoint = false;

      if (index === 0) {
        // Check if first point is orphaned.
        const nextElement = sourceLine.data[index + 1];

        if (isEmpty(nextElement?.y) && !isEmpty(element?.y)) {
          pushPoint = true;
        }
      } else if (index === sourceLine.data.length - 1) {
        // Check if end point is orphaned.
        const prevElement = sourceLine.data[index - 1];

        if (isEmpty(prevElement?.y) && !isEmpty(element?.y)) {
          pushPoint = true;
        }
      } else {
        // Check if point is orphaned in the middle of the series.
        const nextElement = sourceLine.data[index + 1];
        const prevElement = sourceLine.data[index - 1];

        if (
          isEmpty(nextElement?.y) &&
          isEmpty(prevElement?.y) &&
          !isEmpty(element?.y)
        ) {
          pushPoint = true;
        }
      }

      if (pushPoint) {
        pointLine?.data.push(element);
        solidLine?.data.push({ y: null });
      } else {
        pointLine?.data.push({ y: null });
        solidLine?.data.push(element);
      }
    });
  } else {
    // With only one point, we are just going to use our source line data.
    pointLine.data = sourceLine.data;
  }

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore: Not sure why this is complaining that y doesn't exist on point.
  const hasPointYPoints = pointLine.data.some((point) => point?.y !== null);
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore: Not sure why this is complaining that y doesn't exist on point.
  const hasSolidYPoints = solidLine.data.some((point) => point.y !== null);

  if (!hasPointYPoints) {
    pointLine = null;
  }

  if (!hasSolidYPoints) {
    solidLine = null;
  }

  // If both lines have data, link them together so the chart treats them as one
  // (ie - legend, hover, etc).
  if (solidLine && pointLine) {
    const solidLineId = `${Math.random() * 1000}`;
    solidLine.id = solidLineId;
    pointLine.linkedTo = solidLineId;
  }

  return { pointLine, solidLine };
}
