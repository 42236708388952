import Model, { attr } from '@ember-data/model';

export default class LocationModel extends Model {
  @attr() declare address: string;
  @attr() declare latitude: number;
  @attr() declare longitude: number;
  @attr() declare name: string | null;
}

// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    location: LocationModel;
  }
}
