import Model, { attr } from '@ember-data/model';

export default class RideSummaryModel extends Model {
  @attr() declare label: string;
  @attr() declare value: string | number;
}

// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'ride-summary': RideSummaryModel;
  }
}
