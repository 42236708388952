import Route from '@ember/routing/route';
import { tracked } from '@glimmer/tracking';
import { service } from '@ember/service';
import type Store from '@ember-data/store';
import { action } from '@ember/object';
import type VetrideTripModel from 'onward-portal/models/vetride-trip';
import type { PageMeta } from '../../../../components/ui-pagination';
import type Transition from '@ember/routing/transition';
import type NewRideRequestsController from 'onward-portal/controllers/authenticated/va-tools/ride-requests/new';

type NewRideRequestModel = {
  vetrideTrips: VetrideTripModel[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  meta: PageMeta;
};

export default class NewRideRequestsRoute extends Route {
  @service declare store: Store;

  @tracked isEnteringRoute = true;

  queryParams = {
    accountId: {
      refreshModel: true,
    },
    page: {
      refreshModel: true,
    },
    term: {
      refreshModel: true,
    },
  };

  async model(params: {
    accountId: string | null;
    page: number;
    term: string;
  }): Promise<NewRideRequestModel> {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const filters: Record<string, any> = {};

    if (params.accountId !== null) {
      filters['accountId'] = params.accountId;
    }

    if (params.term !== '') {
      filters['term'] = params.term;
    }

    const vetrideTrips = await this.store.query('vetride-trip', {
      request_status: 'new',
      page: params.page,
      ...filters,
    });

    return {
      vetrideTrips: vetrideTrips.slice(),
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore: Figure out how to type this.
      meta: vetrideTrips.meta,
    };
  }

  @action
  didTransition() {
    // Animate scrolling to the top of the page when params change.
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }

  @action
  deactivate() {
    // This is needed so that we show the route loading spinner if the user
    // comes back to the page later.
    this.isEnteringRoute = true;
  }

  @action
  loading(transition: Transition) {
    if (this.isEnteringRoute) {
      this.isEnteringRoute = false;
      return true;
    }

    const controller = <NewRideRequestsController>(
      // eslint-disable-next-line ember/no-controller-access-in-routes
      this.controllerFor(this.routeName)
    );
    controller.isLoading = true;

    transition.finally(() => {
      controller.isLoading = false;
    });
  }
}
