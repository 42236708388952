import ReportingAdapter from './namespaces/reporting';

export default class AcceptanceMedianTimeAdapter extends ReportingAdapter {}

// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/adapter' {
  export default interface AdapterRegistry {
    'acceptance-median-time': AcceptanceMedianTimeAdapter;
  }
}
