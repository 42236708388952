import { template } from "@ember/template-compiler";
import Component from '@glimmer/component';
import type { EmptyObject } from '@ember/component/helper';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import set from 'ember-set-helper/helpers/set';
import type UiFormGroupComponent from '@onwardcare/ui-components/components/ui-form/group';
import UiValidationErrors, { type ValidationError } from '@onwardcare/ui-components/components/ui-validation-errors';
function emptyStringToNull(value1: string | null) {
    return value1 === '' ? null : value1;
}
export interface DateRange {
    startDate: string | null;
    endDate: string | null;
}
export interface CustomDateRangeSignature {
    Element: HTMLDivElement;
    Args: {
        // TODO: Need to figure out how to properly use the `UiForm` block types.
        form: {
            Group: typeof UiFormGroupComponent;
        };
        dateRange: DateRange;
        onChange: (dateRange: DateRange) => unknown;
    };
    Blocks: EmptyObject;
}
export default class CustomDateRangeComponent extends Component<CustomDateRangeSignature> {
    @tracked
    errors: ValidationError[] = [];
    @tracked
    _startDate: string = this.args.dateRange.startDate ?? '';
    @tracked
    _endDate: string = this.args.dateRange.endDate ?? '';
    get hasErrors() {
        return this.errors.length > 0;
    }
    validateRange(startDate1: string, endDate1: string) {
        if (startDate1 !== '' && endDate1 !== '' && startDate1 > endDate1) {
            this.errors = [
                {
                    message: 'End Date must be on or after the Start Date'
                }
            ];
            return false;
        }
        this.errors = [];
        return true;
    }
    @action
    dateChanged() {
        const { _startDate: _startDate1, _endDate: _endDate1 } = this;
        if (this.validateRange(_startDate1, _endDate1) === false) {
            return;
        }
        this.args.onChange({
            startDate: emptyStringToNull(_startDate1),
            endDate: emptyStringToNull(_endDate1)
        });
    }
    static{
        template(`
    <div class='flex flex-col gap-1' ...attributes>
      <div class='flex gap-2'>
        <@form.Group data-test-id='start-date' class='w-full' as |Group|>
          <Group.Label
            class='flex gap-1 items-center'
            @hasErrors={{this.hasErrors}}
          >
            Start Date
          </Group.Label>
          <Group.Input
            type='date'
            @value={{this._startDate}}
            @hasErrors={{this.hasErrors}}
            @onBlur={{this.dateChanged}}
            @onChange={{set this '_startDate'}}
          />
        </@form.Group>
        <@form.Group data-test-id='end-date' class='w-full' as |Group|>
          <Group.Label
            class='flex gap-1 items-center'
            @hasErrors={{this.hasErrors}}
          >
            End Date
          </Group.Label>
          <Group.Input
            type='date'
            @value={{this._endDate}}
            @hasErrors={{this.hasErrors}}
            @onBlur={{this.dateChanged}}
            @onChange={{set this '_endDate'}}
          />
        </@form.Group>
      </div>
      <UiValidationErrors @errors={{this.errors}} />
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
declare module '@glint/environment-ember-loose/registry' {
    export default interface Registry {
        'Filters::CustomDateRange': typeof CustomDateRangeComponent;
    }
}
