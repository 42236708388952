import { template } from "@ember/template-compiler";
import Component from '@glimmer/component';
import { service } from '@ember/service';
import { action } from '@ember/object';
import type { EmptyObject } from '@ember/component/helper';
import type SessionService from '../../services/onward-session';
import UiMenu from '../ui-menu';
import UiIcon from '@onwardcare/ui-components/components/ui-icon';
export interface UserMenuSignature {
    Element: HTMLDivElement;
    Args: EmptyObject;
    Blocks: EmptyObject;
}
export default class UserMenuComponent extends Component<UserMenuSignature> {
    @service('onward-session')
    session: SessionService;
    get profileInitials() {
        if (!this.session.isAuthenticated || !this.session.user) {
            return null;
        }
        const { firstName: firstName1, lastName: lastName1 } = this.session.user;
        let initials1 = '';
        if (firstName1 && firstName1[0]) {
            initials1 += firstName1[0].toUpperCase();
        }
        if (lastName1 && lastName1[0]) {
            initials1 += lastName1[0].toUpperCase();
        }
        return initials1;
    }
    @action
    signOut() {
        this.session.invalidate();
    }
    static{
        template(`
    <UiMenu as |menu|>
      <menu.Trigger
        class='-m-1.5 flex items-center p-1.5 focus:outline-purple-700'
      >

        <span
          class='inline-flex h-10 w-10 items-center justify-center rounded-full bg-gray-500'
        >
          <span class='font-medium leading-none text-white'>
            {{this.profileInitials}}
          </span>
        </span>

        <span class='hidden lg:flex lg:items-center'>
          <span
            class='ml-4 text-sm font-semibold leading-6 text-gray-900'
            aria-hidden='true'
          >
            {{this.session.user.fullName}}
          </span>
          <UiIcon
            @icon='chevron-down'
            @type='mini'
            class='ml-2 text-gray-400'
          />
        </span>
      </menu.Trigger>

      <menu.Button @onClick={{this.signOut}}>
        Sign out
      </menu.Button>
    </UiMenu>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
declare module '@glint/environment-ember-loose/registry' {
    export default interface Registry {
        'MainLayout::UserMenu': typeof UserMenuComponent;
    }
}
