import Component from '@glimmer/component';
import type { WithBoundArgs } from '@glint/template';
import type UiComboboxOptionComponent from './ui-combobox/option.ts';
import { action } from '@ember/object';
import type UiComboboxEmptyComponent from './ui-combobox/empty.gts';

export interface UiComboboxSignature {
  Element: HTMLDivElement;
  Args: {
    id: string;
    labelId?: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    value?: any | null;
    placeholder?: string;
    allowClear?: boolean;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onChange?: (value: any | null) => void;
    onFilterChange?: (value: string) => void;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    displayValue?: (value: any | null) => string;
  };
  Blocks: {
    default: [
      {
        Empty: WithBoundArgs<typeof UiComboboxEmptyComponent, never>;
        Option: WithBoundArgs<
          typeof UiComboboxOptionComponent,
          'optionComponent'
        >;
      },
    ];
  };
}

export default class UiComboboxComponent extends Component<UiComboboxSignature> {
  get showClear() {
    return this.args.allowClear && this.args.value !== null;
  }

  @action
  handleFilterChange(event: Event) {
    const target = event.target as HTMLInputElement;
    this.args.onFilterChange?.(target.value);
  }

  @action
  handleClear(event: Event) {
    // This prevents event propagation so that it doesn't open up the listbox
    // options when the clear button is clicked.
    event.stopPropagation();
    this.args.onChange?.(null);
  }
}
