/* import __COLOCATED_TEMPLATE__ from './ui-slideover.hbs'; */
import Component from '@glimmer/component';

export interface UiSlideoverSignature {
  Element: HTMLDivElement;
  Args: {
    isOpen: boolean;
    onClose: () => unknown;
  };
  Blocks: {
    default: [];
  };
}

// eslint-disable-next-line ember/no-empty-glimmer-component-classes
export default class UiSlideoverComponent extends Component<UiSlideoverSignature> {}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    UiSlideover: typeof UiSlideoverComponent;
  }
}
