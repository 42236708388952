import type AdapterError from '@ember-data/adapter/error';

export interface ResponseError {
  detail: string;
  status: string;
  title: string;
}

export interface AdapterResponseError extends AdapterError {
  errors: ResponseError[];
}

/**
 * Parses the HTTP error from the exception for Ember Data requests.
 *
 * @param exception The thrown exception.
 * @returns Returns the HTTP error object.
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function parseHttpError(exception: any) {
  // This parses out the error from Ember Data.
  if (exception.httpErrorResponse && exception.httpErrorResponse.payload) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return exception.httpErrorResponse.payload as Record<string, any>;
  }

  return null;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function extractErrorMessage(exception: any, defaultMessage?: string) {
  const error = parseHttpError(exception);

  if (error === null) {
    return null;
  }

  const { message, status } = error;

  if (status === 422 && typeof message === 'object') {
    // No need to return an error message since it will be handled by Ember Data.
    return null;
  }

  if (message) {
    // Use the error message from the exception.
    return message;
  }

  // Return the default message if it was passed in.
  return defaultMessage ?? null;
}
