import Service, { service } from '@ember/service';
import type SessionService from './onward-session';
import type Store from '@ember-data/store';
import { restartableTask } from 'ember-concurrency';
import { tracked } from '@glimmer/tracking';
import type AccountModel from '../models/account';
import { action } from '@ember/object';
import type StorageService from './storage';

const RECENT_ACCOUNTS_KEY = 'recentAccounts';
const MAX_RECENT_ACCOUNTS = 10;

export default class AccountService extends Service {
  @service('onward-session') declare session: SessionService;
  @service declare storage: StorageService;
  @service declare store: Store;

  @tracked recentAccounts: AccountModel[] = [];
  @tracked userAccount: AccountModel | null = null;

  get isLoading() {
    return (
      this.fetchRecentAccounts.isRunning || this.fetchUserAccount.isRunning
    );
  }

  fetchRecentAccounts = restartableTask(async () => {
    const recentAccountIds = this._loadRecentAccountIds();

    if (recentAccountIds.length === 0) {
      return [];
    }

    const accounts = await this.store.query('account', {
      ids: recentAccountIds,
    });

    this.recentAccounts = accounts.slice();
  });

  fetchUserAccount = restartableTask(async () => {
    const userAccountId = this.session.user
      ? this.session.user.accountId.toString()
      : null;

    // This should never happen.
    if (userAccountId === null) {
      return null;
    }

    this.userAccount = await this.store.findRecord('account', userAccountId, {
      include: 'parent_account',
    });
  });

  @action
  selectAccount(account: AccountModel) {
    if (this.session.user?.accountId.toString() === account.id) {
      this.session.accountContext = null;
    } else {
      this.session.accountContext = account;
      this._storeAccount(account);
    }
  }

  _loadRecentAccountIds() {
    const recentAccountValue = this.storage.getItem(RECENT_ACCOUNTS_KEY);
    return recentAccountValue
      ? (JSON.parse(recentAccountValue) as string[])
      : [];
  }

  _saveRecentAccountIds(accountIds: string[]) {
    this.storage.setItem(RECENT_ACCOUNTS_KEY, JSON.stringify(accountIds));
  }

  _storeAccount(account: AccountModel) {
    let recentAccountIds = this._loadRecentAccountIds();

    // Check to see if the account already exists in the recents.
    if (recentAccountIds.some((id) => id === account.id)) {
      return;
    }

    this.recentAccounts = [...this.recentAccounts, account];
    recentAccountIds = [...recentAccountIds, account.id];

    if (recentAccountIds.length > MAX_RECENT_ACCOUNTS) {
      // Remove the oldest account.
      recentAccountIds.shift();
      this.recentAccounts.shift();
    }

    this._saveRecentAccountIds(recentAccountIds);
  }
}

// Don't remove this declaration: this is what enables TypeScript to resolve
// this service using `Owner.lookup('service:account')`, as well
// as to check when you pass the service name as an argument to the decorator,
// like `@service('account') declare altName: AccountService;`.
declare module '@ember/service' {
  interface Registry {
    account: AccountService;
  }
}
