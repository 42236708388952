import type { DateGrouping } from './filters/date-range';

const LABEL_COLOR = '#b91c1c'; // red-700

export function partialOrFutureDateXAxisFormatter(
  isPartial: boolean,
  value: string | number,
) {
  if (isPartial) {
    return `<span style="color: ${LABEL_COLOR}">${value}*</span>`;
  }

  return `<span>${value}</span>`;
}

export function partialOrFutureDateXAxisTitleOptions(
  dateGrouping: DateGrouping,
) {
  return {
    text: `* Denotes partial or future ${dateGrouping}`,
    style: {
      color: LABEL_COLOR,
    },
  };
}

export interface ChartParams {
  accountIds: string[];
  channel: 'b2b' | 'b2c' | null;
  dateGrouping: DateGrouping;
  endDate: string;
  payerTypes: string[];
  geoId: string | null;
  parentAccountId: string | null;
  regionIds: string[];
  rideCompletionStatuses: string[];
  startDate: string;
  transportPartnerIds: string[];
  transportTypeIds: string[];
  zipcodeIds: string[];
}
