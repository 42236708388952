import { template } from "@ember/template-compiler";
import type { TOC } from '@ember/component/template-only';
import type { EmptyObject } from '@ember/component/helper';
import { ageFromDob, dateStringToDateFormat } from '@onwardcare/core/utils/date-format';
import BanIcon from '../icons/ban-icon';
import EarIcon from '../icons/ear-icon';
import EyeIcon from '../icons/eye-icon';
import LightBulbIcon from '../icons/light-bulb-icon';
import PersonWalkingWithCaneIcon from '../icons/person-walking-with-cane-icon';
import type RiderModel from '../../models/rider';
import SectionHeader from './section-header';
import TooltipButton from '../tooltip-button';
import UiAvatar from '../ui-avatar';
const RiderCard: TOC<{
    Element: HTMLDivElement;
    Args: {
        rider: RiderModel;
    };
    Blocks: EmptyObject;
}> = template(`
  <div data-test-id='rider-card' class='flex flex-col gap-3'>
    <SectionHeader class='flex gap-1 items-baseline'>
      Rider

      <span data-test-id='rider-id' class='text-gray-600 text-xs'>
        ({{@rider.id}})
      </span>
    </SectionHeader>

    <div class='flex flex-wrap gap-3 justify-between items-center'>
      <div class='flex gap-2 items-center'>
        <UiAvatar @photoUrl={{@rider.profilePhotoUrl}} />

        <div>
          <div class='flex gap-1 flex-wrap items-baseline'>
            <span class='inline-flex'>
              <span data-test-id='rider-name' class='font-medium'>
                {{@rider.displayName}}
              </span>
              {{if @rider.gender ','}}
            </span>

            {{#if @rider.gender}}
              <span
                data-test-id='rider-gender'
                class='inline-flex text-sm text-gray-500 font-normal'
              >
                {{@rider.gender}}
              </span>
            {{/if}}
          </div>

          {{#if @rider.dob}}
            <div>
              <span data-test-id='rider-dob' class='text-sm'>
                {{dateStringToDateFormat @rider.dob}}
              </span>
              <span
                data-test-id='rider-age'
                class='text-gray-500 text-xs italic'
              >
                ({{ageFromDob @rider.dob}})
              </span>
            </div>
          {{/if}}
        </div>
      </div>

      <div class='flex gap-2 items-center'>
        {{#unless @rider.canMakeChanges}}
          <TooltipButton
            data-test-id='rider-changes'
            class='text-gray-500 hover:text-gray-400'
          >
            <:button>
              <BanIcon class='w-5 h-5' />
              <span class='sr-only'>Can't make ride changes</span>
            </:button>
            <:tooltip>
              This rider can't make ride changes. All changes should be made by
              a coordinator.
            </:tooltip>
          </TooltipButton>
        {{/unless}}

        {{#if @rider.mobilityAssistance}}
          <TooltipButton
            data-test-id='rider-mobility'
            class='text-gray-500 hover:text-gray-400'
          >
            <:button>
              <PersonWalkingWithCaneIcon class='w-5 h-5' />
              <span class='sr-only'>Mobility Assistance</span>
            </:button>
            <:tooltip>
              {{@rider.mobilityAssistance}}
            </:tooltip>
          </TooltipButton>
        {{/if}}

        {{#if @rider.visionImpairment}}
          <TooltipButton
            data-test-id='rider-vision'
            class='text-gray-500 hover:text-gray-400'
          >
            <:button>
              <EyeIcon class='w-5 h-5' />
              <span class='sr-only'>Vision Impairment</span>
            </:button>
            <:tooltip>
              {{@rider.visionImpairment}}
            </:tooltip>
          </TooltipButton>
        {{/if}}

        {{#if @rider.hearingImpairment}}
          <TooltipButton
            data-test-id='rider-hearing'
            class='text-gray-500 hover:text-gray-400'
          >
            <:button>
              <EarIcon class='w-5 h-5' />
              <span class='sr-only'>Hearing Impairment</span>
            </:button>
            <:tooltip>
              {{@rider.hearingImpairment}}
            </:tooltip>
          </TooltipButton>
        {{/if}}

        {{#if @rider.cognitiveImpairment}}
          <TooltipButton
            data-test-id='rider-cognitive'
            class='text-gray-500 hover:text-gray-400'
          >
            <:button>
              <LightBulbIcon class='w-5 h-5' />
              <span class='sr-only'>Cognitive Impairment</span>
            </:button>
            <:tooltip>
              {{@rider.cognitiveImpairment}}
            </:tooltip>
          </TooltipButton>
        {{/if}}
      </div>
    </div>

    <div class='mt-3 flex flex-col gap-2 text-sm'>
      {{#if @rider.language}}
        <div class='flex gap-1'>
          <span class='text-gray-600'>Language:</span>
          <span data-test-id='rider-language'>
            {{@rider.language.displayName}}
          </span>
        </div>
      {{/if}}
    </div>

    {{#if @rider.notes}}
      <div class='text-sm'>
        <div class='text-gray-500'>Rider Notes</div>
        <p data-test-id='rider-notes'>
          {{@rider.notes}}
        </p>
      </div>
    {{/if}}

    {{#if @rider.internalNotes}}
      <div class='text-sm'>
        <div class='text-gray-500'>Internal Notes</div>
        <p data-test-id='rider-internal-notes'>
          {{@rider.internalNotes}}
        </p>
      </div>
    {{/if}}

    {{#if @rider.privateDriverNotes}}
      <div class='text-sm'>
        <div class='text-gray-500'>Private Driver Notes</div>
        <p data-test-id='rider-private-driver-notes'>
          {{@rider.privateDriverNotes}}
        </p>
      </div>
    {{/if}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default RiderCard;
declare module '@glint/environment-ember-loose/registry' {
    export default interface Registry {
        'Rides::RiderCard': typeof RiderCard;
    }
}
