import Model, { attr } from '@ember-data/model';
import type { PieSeries } from '@onwardcare/ui-components/types';

export default class PieChartModel extends Model {
  @attr() declare series: PieSeries[];
}

// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'pie-chart': PieChartModel;
  }
}
