import { template } from "@ember/template-compiler";
import type { TOC } from '@ember/component/template-only';
import type { EmptyObject } from '@ember/component/helper';
import type { WithBoundArgs } from '@glint/template';
import { hash } from '@ember/helper';
interface ListItemSignature {
    Element: HTMLDivElement;
    Args: EmptyObject;
    Blocks: {
        key: [];
        value: [];
    };
}
const ListItem: TOC<ListItemSignature> = template(`
  <div class='lg:grid lg:grid-cols-3 lg:gap-4' ...attributes>
    <dt class='text-sm font-medium leading-6 text-gray-900'>
      {{yield to='key'}}
    </dt>
    <dd class='mt-1 text-sm leading-6 text-gray-700 lg:mt-0 lg:col-span-2'>
      {{yield to='value'}}
    </dd>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export interface UiDescriptionListSignature {
    Element: HTMLDListElement;
    Args: EmptyObject;
    Blocks: {
        default: [{
                Item: WithBoundArgs<typeof ListItem, never>;
            }];
    };
}
const UiDescriptionListComponent: TOC<UiDescriptionListSignature> = template(`
  <dl class='flex flex-col gap-5' ...attributes>
    {{yield (hash Item=(component ListItem))}}
  </dl>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default UiDescriptionListComponent;
declare module '@glint/environment-ember-loose/registry' {
    export default interface Registry {
        UiDescriptionList: typeof UiDescriptionListComponent;
    }
}
