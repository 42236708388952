import VaToolsAdapter from './namespaces/va-tools';
import type VetrideTripModel from 'onward-portal/models/vetride-trip';
import type { MatchingRide } from 'onward-portal/models/vetride-trip';

export default class VetrideTripAdapter extends VaToolsAdapter {
  /**
   * This basically returns a `VetrideTripModel`, but it's not serialized.
   * @param model The `VetrideTripModel` instance.
   * @returns Returns the associated trip for the given `VetrideTripModel` instance.
   */
  async associatedTrip(
    model: VetrideTripModel,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ): Promise<Record<string, any> | null> {
    const baseUrl = this.buildURL();
    const url = `${baseUrl}/vetride_trips/${model.id}/associated_trip`;
    const response = await fetch(url, {
      headers: this.headers,
    });

    if (response.status === 200) {
      const json = await response.json();
      return json.vetride_trip ?? null;
    }

    return null;
  }

  /**
   * This returns a subset of the Onward Ride properties, and doesn't go through
   * a serializer.
   * @param model The `VetrideTripModel` instance.
   * @returns Returns an array of matching Onward rides for the given `VetrideTripModel` instance.
   */
  async matchingRides(model: VetrideTripModel): Promise<MatchingRide[]> {
    const baseUrl = this.buildURL();
    const url = `${baseUrl}/vetride_trips/${model.id}/matching_rides`;
    const response = await fetch(url, {
      headers: this.headers,
    });

    if (response.status === 200) {
      const json = await response.json();
      return json.rides ?? [];
    }

    return [];
  }
}

// DO NOT DELETE: this is how TypeScript knows how to look up your adapters.
declare module 'ember-data/types/registries/adapter' {
  export default interface AdapterRegistry {
    'vetride-trip': VetrideTripAdapter;
  }
}
