// ******
// THIS FILE MUST BE A JAVASCRIPT FILE AND NOT A TYPESCRIPT FILE!!!
//
// For some reason, when this is a TypeScript file, the `ember-simple-auth`
// addon isn't detecting it. I tried to reproduce this in a new app and
// couldn't, so there must be something we are doing wrong, I just done't see
// it right now.
//
// ******

import Cookie from 'ember-simple-auth/session-stores/cookie';
import { add, differenceInSeconds } from 'date-fns';
import { service } from '@ember/service';

export default class ApplicationSessionStore extends Cookie {
  @service settings;

  cookieDomain = this.getDomain();
  cookieExpirationTime = this.getExpirationTime();
  cookieName = `${this.getCookiePrefix()}onward-session`;
  sameSite = 'Strict';

  getDomain() {
    const host = window.location.hostname;
    return host.includes('localhost')
      ? 'localhost'
      : // Strip off the subdomain. This assumes that there is always a subdomain,
        // which is true for all our apps.
        host.substring(host.indexOf('.'));
  }

  getExpirationTime() {
    const now = new Date();
    const expiration = add(now, { days: 30, hours: 12 });
    return differenceInSeconds(expiration, now);
  }

  getCookiePrefix() {
    const environment = this.settings.getEnvironment();
    return environment === 'production' ? '' : `${environment}-`;
  }
}
