import Model, { attr } from '@ember-data/model';

export default class VehicleModel extends Model {
  @attr() declare color: string;
  @attr() declare licensePlate: string;
  @attr() declare make: string;
  @attr() declare model: string;
  @attr() declare year: number;
}

// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    vehicle: VehicleModel;
  }
}
