import Route from '@ember/routing/route';
import { service } from '@ember/service';
import type Store from '@ember-data/store';
import { action } from '@ember/object';
import type VetrideTripModel from 'onward-portal/models/vetride-trip';

type RideDetailsModel = {
  rideRequest: VetrideTripModel;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  associatedRideRequest: Record<string, any> | null;
};
export default class RideDetailsRoute extends Route {
  @service declare store: Store;

  async model({ id }: { id: string }): Promise<RideDetailsModel> {
    const rideRequest = await this.store.findRecord('vetride-trip', id);
    const associatedRideRequest = await rideRequest.associatedTrip();

    return {
      rideRequest,
      associatedRideRequest,
    };
  }

  @action
  activate() {
    // Makes sure that the page gets scrolled to the top when changing routes.
    window.scrollTo(0, 0);
  }
}
