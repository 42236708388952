import { template } from "@ember/template-compiler";
import Component from '@glimmer/component';
import type { EmptyObject } from '@ember/component/helper';
import { service } from '@ember/service';
import { on } from '@ember/modifier';
import { fn } from '@ember/helper';
import type AccountModel from '../../../models/account';
import type AccountService from '../../../services/account';
import UiIcon from '@onwardcare/ui-components/components/ui-icon';
export interface RecentAccountItemSignature {
    Element: HTMLLIElement;
    Args: {
        account: AccountModel;
        onSelect: (account: AccountModel) => void;
    };
    Blocks: EmptyObject;
}
export default class RecentAccountItemComponent extends Component<RecentAccountItemSignature> {
    @service
    account: AccountService;
    get isUserAccount() {
        return this.account.userAccount?.id === this.args.account.id;
    }
    static{
        template(`
    <li>
      <button
        type='button'
        data-test-id='recent-account'
        class='flex w-full items-center justify-between gap-4 px-4 py-2 hover:bg-gray-50'
        {{on 'click' (fn @onSelect @account)}}
      >
        <div class='text-left'>
          <span>
            {{@account.name}}
          </span>

          {{#if this.isUserAccount}}
            <span
              data-test-id='my-account'
              class='inline-flex items-center whitespace-nowrap rounded-md bg-purple-50 px-2 py-1 text-xs font-medium text-purple-800 ring-1 ring-inset ring-purple-700/10'
            >
              My Account
            </span>
          {{/if}}
        </div>

        <div>
          <UiIcon @icon='chevron-right' @type='mini' />
        </div>
      </button>
    </li>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
declare module '@glint/environment-ember-loose/registry' {
    export default interface Registry {
        'SelectAccount::RecentsList::RecentAccountItem': typeof RecentAccountItemComponent;
    }
}
