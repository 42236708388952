import JSONAPISerializer from '@ember-data/serializer/json-api';
import type Model from '@ember-data/model';
import type Store from '@ember-data/store';
import { underscore } from '@ember/string';
import { camelizeObjectKeys } from '../../utils/serialize';

export default class OnwardJSONAPISerializer extends JSONAPISerializer {
  keyForAttribute(attr: string) {
    return underscore(attr);
  }

  keyForRelationship(key: string, relationship: string, method: string) {
    return underscore(key);
  }

  normalizeResponse(
    store: Store,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    primaryModelClass: any,
    payload: Record<string, unknown>,
    id: Model['id'],
    requestType: string,
  ) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const response: Record<string, any> = super.normalizeResponse(
      store,
      primaryModelClass,
      payload,
      id,
      requestType,
    );

    if ('meta' in response) {
      response['meta'] = camelizeObjectKeys(response['meta']);
    }

    return response;
  }
}

// DO NOT DELETE: this is how TypeScript knows how to look up your serializers.
declare module 'ember-data/types/registries/serializer' {
  export default interface SerializerRegistry {
    'onward-jsonapi': OnwardJSONAPISerializer;
  }
}
