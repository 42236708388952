import { template } from "@ember/template-compiler";
import Component from '@glimmer/component';
import type { EmptyObject } from '@ember/component/helper';
import { service } from '@ember/service';
import { hash } from '@ember/helper';
import type AccountModel from '../models/account';
import type AccountService from '../services/account';
export interface RecentAccountsProviderSignature {
    Element: void;
    Args: EmptyObject;
    Blocks: {
        default: [{
                recentAccounts: AccountModel[];
                userAccount: AccountModel | null;
                isLoading: boolean;
            }];
    };
}
export default class RecentAccountsProviderComponent extends Component<RecentAccountsProviderSignature> {
    @service
    account: AccountService;
    static{
        template(`
    {{yield
      (hash
        recentAccounts=this.account.recentAccounts
        userAccount=this.account.userAccount
        isLoading=this.account.isLoading
      )
    }}
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
declare module '@glint/environment-ember-loose/registry' {
    export default interface Registry {
        RecentAccountsProvider: typeof RecentAccountsProviderComponent;
    }
}
