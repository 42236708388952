import Model, { attr } from '@ember-data/model';

export default class UserModel extends Model {
  @attr() declare email: string;
  @attr() declare firstName: string;
  @attr() declare lastName: string;
  @attr() declare accountId: number;
  @attr() declare accountName: string;
  @attr() declare parentAccountId: number | null;
  @attr() declare parentAccountName: string | null;
  @attr() declare roles: string[];

  get fullName() {
    const { firstName, lastName } = this;
    return `${firstName} ${lastName}`;
  }
}

// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    user: UserModel;
  }
}
