import Service from '@ember/service';

export default class StorageService extends Service {
  getItem(key: string) {
    return localStorage.getItem(key);
  }

  setItem(key: string, value: string) {
    localStorage.setItem(key, value);
  }

  removeItem(key: string) {
    localStorage.removeItem(key);
  }

  clear() {
    localStorage.clear();
  }
}

// Don't remove this declaration: this is what enables TypeScript to resolve
// this service using `Owner.lookup('service:storage')`, as well
// as to check when you pass the service name as an argument to the decorator,
// like `@service('storage') declare altName: StorageService;`.
declare module '@ember/service' {
  interface Registry {
    storage: StorageService;
  }
}
