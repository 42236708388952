import { modifier } from 'ember-modifier';
import type { IconType } from '../components/ui-icon.ts';

type Named = { type?: IconType; inheritSize?: boolean };

export interface InitIconModifierSignature {
  Element: SVGElement;
  Args: {
    Positional: [];
    Named: Named;
  };
}

// Apply a default width/height to the icons if one hasn't been applied.
function applySizeClasses(
  element: InitIconModifierSignature['Element'],
  type?: Named['type'],
  inheritSize?: Named['inheritSize'],
) {
  const classes = element.classList.toString().split(' ');
  const hasSizeClass = classes.some(
    // If either a width or height class has been applied, don't try to add a
    // size class. It's possible that only one of the size classes has been
    // applied for the other one to be auto sized.
    (className) =>
      className.startsWith('w-') ||
      className.includes(':w-') ||
      className.startsWith('h-') ||
      className.includes(':h-'),
  );

  if (hasSizeClass) {
    return;
  }

  if (inheritSize) {
    element.classList.add('h-[1em]');
  } else if (type === 'mini') {
    element.classList.add('w-5', 'h-5');
  } else if (type === 'micro') {
    element.classList.add('w-4', 'h-4');
  } else {
    element.classList.add('w-6', 'h-6');
  }
}

const initChart = modifier<InitIconModifierSignature>(
  (
    element: InitIconModifierSignature['Element'],
    positional: [],
    { type, inheritSize }: Named,
  ) => {
    applySizeClasses(element, type, inheritSize);

    function callback(mutationList: MutationRecord[]) {
      mutationList.forEach((mutation) => {
        if (
          mutation.type === 'attributes' &&
          mutation.attributeName === 'class'
        ) {
          applySizeClasses(element, type, inheritSize);
        }
      });
    }

    const observerOptions = {
      attributes: true,
    };

    const observer = new MutationObserver(callback);
    observer.observe(element, observerOptions);

    return () => {
      observer.disconnect();
    };
  },
);

export default initChart;
