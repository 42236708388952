import Component from '@glimmer/component';
import type { WithBoundArgs } from '@glint/template';
import { action } from '@ember/object';
import type UiListboxOptionComponent from './ui-listbox/option.gts';
import type UiListboxButtonComponent from './ui-listbox/button.gts';
import type UiListboxEmptyComponent from './ui-listbox/empty.gts';

export interface UiListboxSignature {
  Element: HTMLDivElement;
  Args: {
    allowClear?: boolean;
    disabled?: boolean;
    id: string;
    labelId?: string;
    multiple?: boolean;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onChange?: (value: any | null) => void;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    value?: any | null;
  };
  Blocks: {
    default: [
      {
        Button: WithBoundArgs<typeof UiListboxButtonComponent, never>;
        Empty: WithBoundArgs<typeof UiListboxEmptyComponent, 'optionComponent'>;
        Option: WithBoundArgs<
          typeof UiListboxOptionComponent,
          'optionComponent'
        >;
      },
    ];
  };
}

const baseClasses =
  'relative transition h-9 w-full cursor-default rounded-md py-1.5 pl-3 text-left shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-purple-700 sm:text-sm sm:leading-6';

export default class UiListboxComponent extends Component<UiListboxSignature> {
  get baseClasses() {
    return baseClasses;
  }

  get showClear() {
    if (this.args.disabled) {
      return false;
    }

    if (this.args.multiple) {
      if (Array.isArray(this.args.value)) {
        return this.args.value.length > 0;
      }

      return this.args.value !== null;
    } else {
      return this.args.allowClear && this.args.value !== null;
    }
  }

  @action
  handleClear(event: Event) {
    // This prevents event propagation so that it doesn't open up the listbox
    // options when the clear button is clicked.
    event.stopPropagation();
    this.args.onChange?.(this.args.multiple ? [] : null);
  }
}
