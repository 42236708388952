/**
 * Formats a number to a string in the user's local.
 *
 * Value: 1325
 * Output: 1,325
 *
 * @param value The value to format.
 * @returns The formatted value.
 */
export function formatNumber(value: number | null) {
  return value ? value.toLocaleString() : null;
}

/**
 * Converts cents to a dollar amount string.
 *
 * @param value The value in cents.
 * @returns The value in dollars.
 */
export function centsToDollars(value: number) {
  const cost = value === 0 ? 0 : value / 100;

  return cost.toLocaleString('en-US', {
    style: 'currency',
    currency: 'USD',
  });
}

/**
 * Converts pounds to kilograms. This rounds to the nearest whole number.
 *
 * Value: 160
 * Output: 73
 *
 * @param value The value in pounds.
 * @returns The value in kilograms.
 */
export function poundsToKilograms(value: number) {
  return value === 0 ? 0 : (value / 2.20462).toFixed(0);
}

/**
 * Takes a number of inches and converts it to feet. This rounds down to the
 * nearest whole number.
 *
 * Value: 79
 * Output: 6
 *
 * When used in conjunction with `leftoverInches`, the output would allow you to
 * render something like: 6'7" or 6ft 7in.
 *
 * @param value The value in inches.
 * @returns The value in feet.
 */
export function inchesToFeet(value: number) {
  return value === 0 ? 0 : Math.floor(value / 12);
}

/**
 * After converting the inches to feet, this takes the remaining inches and
 * returns it. This is meant to be used to display height in feet and inches
 * together.
 *
 * Value: 79
 * Output: 7
 *
 * When used in conjunction with `inchesToFeet`, the output would allow you to
 * render something like: 6'7" or 6ft 7in.
 *
 * @param value The value in inches.
 * @returns The remaining inches after converting inches to feet.
 */
export function leftoverInches(value: number) {
  return value === 0 ? 0 : value % 12;
}

/**
 * Converts inches to centimeters. This rounds to the nearest whole number.
 *
 * Value: 79
 * Output: 201
 *
 * @param value The value in inches.
 * @returns The value in centimeters.
 */
export function inchesToCentimeters(value: number) {
  return value === 0 ? 0 : (value * 2.54).toFixed(0);
}
