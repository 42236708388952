import Application from '@ember/application';
import Resolver from 'ember-resolver';
import loadInitializers from 'ember-load-initializers';
import config from 'onward-portal/config/environment';
import { initErrorLogging } from './utils/error-logging';

if (config.environment === 'production') {
  initErrorLogging();
} else {
  console.log('Error logging is disabled in development mode');
}

export default class App extends Application {
  modulePrefix = config.modulePrefix;
  podModulePrefix = config.podModulePrefix;
  Resolver = Resolver;
}

loadInitializers(App, config.modulePrefix);
