import Controller from '@ember/controller';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { service } from '@ember/service';
import type RouterService from '@ember/routing/router-service';
import type { RequestsGridParams } from '../../../../components/va-tools/requests-grid';
import type { Resolved } from 'types';
import type OpenRideRequestsRoute from 'onward-portal/routes/authenticated/va-tools/ride-requests/open';

export default class OpenRideRequestsController extends Controller {
  declare model: Resolved<ReturnType<OpenRideRequestsRoute['model']>>;

  @service declare router: RouterService;

  queryParams = [
    'accountId',
    'hasChanges',
    'page',
    'rideCompletionStatuses',
    'term',
  ];

  @tracked accountId: string | null = null;
  @tracked hasChanges = false;
  @tracked page = 1;
  @tracked rideCompletionStatuses: string[] = [];
  @tracked term = '';
  @tracked isLoading = false;

  get filters() {
    return {
      accountId: this.accountId,
      hasChanges: this.hasChanges,
      rideCompletionStatuses: this.rideCompletionStatuses,
      term: this.term,
    };
  }

  @action
  paramsChanged(params: RequestsGridParams) {
    this.page = params.page ?? 1;
    this.accountId = params.filters?.accountId ?? null;
    this.hasChanges = params.filters?.hasChanges === true ? true : false;
    this.rideCompletionStatuses = params.filters?.rideCompletionStatuses ?? [];
    this.term = params.filters?.term ?? '';
  }

  @action
  requestClosed() {
    // Refresh the route to update the data because changing the vetride trip
    // status to closed doesn't cause a reload.
    this.router.refresh();
  }
}
