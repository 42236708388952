import PieChartModel from './shared/pie-chart';

export default class VolumeByPayerTypeModel extends PieChartModel {}

// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'volume-by-payer-type': VolumeByPayerTypeModel;
  }
}
