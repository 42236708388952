import DefaultChartModel from './shared/default-chart';

export default class RideStatusCountModel extends DefaultChartModel {}

// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'ride-status-count': RideStatusCountModel;
  }
}
