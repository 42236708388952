import ApplicationAdapter from '../application';
import { reportingNamespace } from '../../utils/adapter-namespaces';

export default class ReportingAdapter extends ApplicationAdapter {
  namespace = reportingNamespace;
}

// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/adapter' {
  export default interface AdapterRegistry {
    reporting: ReportingAdapter;
  }
}
