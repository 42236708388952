import * as Sentry from '@sentry/ember';
import config from 'onward-portal/config/environment';
import { getEnvironment } from './environment';

/**
 * Initialize error logging with Sentry.
 */
export function initErrorLogging() {
  Sentry.init({
    dsn: config.sentryDsn,
    release: config.releaseCommit,
    environment: getEnvironment(),
    integrations: [Sentry.browserTracingIntegration()],
    tracesSampleRate: 0.1,
  });
}
