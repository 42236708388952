export type StatusState = 'historical' | 'upcoming';
export type RideCompletionStatus = {
  id:
    | 'confirmed'
    | 'completed'
    | 'cancelled'
    | 'draft'
    | 'in-progress'
    | 'noshow'
    | 'declined'
    | 'pending'
    | 'unfulfilled';
  name: string;
  state: StatusState;
  definition: string;
  displayOrder: number;
};

// Purposfully not including the 'draft' status in this list.
const statuses: RideCompletionStatus[] = [
  {
    id: 'pending',
    name: 'Pending',
    state: 'upcoming',
    definition: 'Ride is scheduled for a future date.',
    displayOrder: 1,
  },
  {
    id: 'confirmed',
    name: 'Confirmed',
    state: 'upcoming',
    definition: 'Ride Confirmed by a driver.',
    displayOrder: 2,
  },
  {
    id: 'in-progress',
    name: 'In Progress',
    state: 'upcoming',
    definition: 'Ride in progress.',
    displayOrder: 3,
  },
  {
    id: 'completed',
    name: 'Completed',
    state: 'historical',
    definition: 'Ride completed.',
    displayOrder: 4,
  },
  {
    id: 'cancelled',
    name: 'Cancelled',
    state: 'historical',
    definition: 'Ride cancelled.',
    displayOrder: 5,
  },
  {
    id: 'noshow',
    name: 'No Show',
    state: 'historical',
    definition:
      'Driver arrived on scene but could not find the rider or the rider was not able to travel as scheduled.',
    displayOrder: 6,
  },
  {
    id: 'declined',
    name: 'Declined',
    state: 'historical',
    definition:
      'Onward declined the booking because we did not have the capacity or time to confirm a driver.',
    displayOrder: 7,
  },
  {
    id: 'unfulfilled',
    name: 'Unfulfilled',
    state: 'historical',
    definition:
      'Onward attempted to fulfill the ride but could not due to driver availability or other factors.',
    displayOrder: 8,
  },
];

export default statuses;
