import ApplicationAdapter from '../application';
import { vectorcareNamespace } from '../../utils/adapter-namespaces';

export default class VectorcareAdapter extends ApplicationAdapter {
  namespace = vectorcareNamespace;
}

// DO NOT DELETE: this is how TypeScript knows how to look up your adapters.
declare module 'ember-data/types/registries/adapter' {
  export default interface AdapterRegistry {
    vectorcare: VectorcareAdapter;
  }
}
