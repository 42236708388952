import Model, { attr } from '@ember-data/model';

export type VetrideRequestStatus = 'new' | 'open' | 'closed';

export interface MatchingRide {
  id: string;
  ride_completion_status: string;
  requested_start_time: string;
  rider: string;
  transport_type: string;
  start_location: string;
  end_location: string;
}

export default class VetrideTripModel extends Model {
  @attr() declare requestStatus: VetrideRequestStatus;
  @attr() declare accountId: number;
  @attr() declare accountName: string;
  @attr() declare accountTz: string;
  @attr() declare tripId: string;
  @attr() declare tripType: string;
  @attr() declare passengerId: number;
  @attr() declare passenger: string;
  @attr() declare phoneNumber: string | null;
  @attr() declare mobility: string;
  @attr() declare extra: string | null;
  @attr('date') declare estimatedPickupTime: Date;
  @attr('date') declare earliestDepartureTime: Date;
  @attr('date') declare latestArrivalTime: Date;
  @attr('date') declare appointmentTime: Date;
  @attr() declare bufferMinutes: number;
  @attr() declare pickupAddressId: number;
  @attr() declare pickupAddress: string;
  @attr() declare dropoffAddressId: number;
  @attr() declare dropoffAddress: string;
  @attr() declare estimatedDistanceMiles: number;
  @attr() declare thirdParty: string | null;
  @attr() declare contractName: string | null;
  @attr() declare contractNumber: string | null;
  @attr() declare status: string;
  @attr() declare startOdometer: number;
  @attr() declare endOdometer: number;
  @attr() declare startTime: string | null;
  @attr() declare endTime: string | null;
  @attr() declare comments: string | null;
  @attr() declare vaNotes: string | null;
  @attr('date') declare createdAt: Date;
  @attr('date') declare closedAt: Date | null;
  @attr() declare closerId: number | null;
  @attr() declare bookAsCustodianId: number;
  @attr() declare changesDiff: Record<string, unknown> | null;

  // Onward Ride Information
  @attr() declare rideId: number | null;
  @attr() declare riderId: number | null;
  @attr('date') declare requestedStartTime?: Date;
  @attr() declare rideCompletionStatus?: string;
  @attr() declare hasMatchingRides: boolean | null;

  associatedTrip() {
    const adapter = this.store.adapterFor('vetride-trip');
    return adapter.associatedTrip(this);
  }

  matchingRides() {
    const adapter = this.store.adapterFor('vetride-trip');
    return adapter.matchingRides(this);
  }
}

// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'vetride-trip': VetrideTripModel;
  }
}
