import { template } from "@ember/template-compiler";
import Component from '@glimmer/component';
import type { EmptyObject } from '@ember/component/helper';
import type UiFormGroupComponent from '@onwardcare/ui-components/components/ui-form/group';
const distances = [
    {
        id: 'long',
        name: 'Long (> 20 miles)'
    },
    {
        id: 'medium',
        name: 'Medium (5-20 miles)'
    },
    {
        id: 'short',
        name: 'Short (< 5 miles)'
    }
];
export interface DistanceFilterSignature {
    Element: HTMLDivElement;
    Args: {
        // TODO: Need to figure out how to properly use the `UiForm` block types.
        form: {
            Group: typeof UiFormGroupComponent;
        };
        distanceIds: string[];
        onChange: (rideTypeIds: string[]) => unknown;
    };
    Blocks: EmptyObject;
}
export default class DistanceFilterComponent extends Component<DistanceFilterSignature> {
    get distanceDisplay() {
        const selectedDistances1 = distances.filter((distance1)=>this.args.distanceIds.includes(distance1.id));
        return selectedDistances1.length > 0 ? selectedDistances1.map((distance1)=>distance1.name).join(', ') : null;
    }
    static{
        template(`
    <@form.Group data-test-id='distance' as |Group|>
      <Group.Label class='flex gap-1 items-center'>
        Distance
      </Group.Label>
      <Group.Listbox
        @multiple={{true}}
        @value={{@distanceIds}}
        @onChange={{@onChange}}
        as |listbox|
      >
        <listbox.Button @placeholder='All Distances'>
          {{this.distanceDisplay}}
        </listbox.Button>

        {{#each distances as |distance|}}
          <listbox.Option @value={{distance.id}}>
            {{distance.name}}
          </listbox.Option>
        {{/each}}
      </Group.Listbox>
      <Group.HelpText>
        This filters on estimated distance.
      </Group.HelpText>
    </@form.Group>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
declare module '@glint/environment-ember-loose/registry' {
    export default interface Registry {
        'Filters::DistanceFilter': typeof DistanceFilterComponent;
    }
}
