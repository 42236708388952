/* import __COLOCATED_TEMPLATE__ from './ui-modal.hbs'; */
import Component from '@glimmer/component';
import { action } from '@ember/object';

export interface UiModalSignature {
  Element: HTMLDivElement;
  Args: {
    isOpen?: boolean;
    onClose?: () => unknown;
  };

  Blocks: {
    body: [{ close: () => unknown }];
    footer: [{ close: () => unknown }];
    title: [{ close: () => unknown }];
  };
}

export default class UiModalComponent extends Component<UiModalSignature> {
  get isOpen() {
    return this.args.isOpen ?? false;
  }

  @action
  onClose() {
    this.args.onClose?.();
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    UiModal: typeof UiModalComponent;
  }
}
