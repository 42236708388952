import Model, { attr, belongsTo, hasMany } from '@ember-data/model';
import type TransportPartnerModel from './transport-partner';
import type UserLanguageModel from './user-language';
import type VehicleModel from './vehicle';
import type VeteranStatusModel from './veteran-status';

export default class DriverModel extends Model {
  @attr() declare age: number | null;
  @attr() declare displayName: string;
  @attr() declare firstName: string;
  @attr() declare gender: string | null;
  @attr() declare isPlaceholder: boolean;
  @attr() declare lastName: string;
  @attr() declare profilePhotoUrl: string | null; // relative path

  @belongsTo('vehicle', { async: false, inverse: null })
  declare currentVehicle: VehicleModel;
  @belongsTo('transport-partner', { async: false, inverse: null })
  declare transportPartner: TransportPartnerModel;
  @belongsTo('veteran-status', { async: false, inverse: null })
  declare veteranStatus: VeteranStatusModel;
  @hasMany('user-language', { async: false, inverse: null })
  declare userLanguages: UserLanguageModel[];
}

// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    driver: DriverModel;
  }
}
