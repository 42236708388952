/* import __COLOCATED_TEMPLATE__ from './ui-menu.hbs'; */
import templateOnlyComponent from '@ember/component/template-only';
import type { EmptyObject } from '@ember/component/helper';
import type { WithBoundArgs } from '@glint/template';
import type UiMenuButtonComponent from './ui-menu/button';
import type UiMenuDividerComponent from './ui-menu/divider';
import type UiMenuTriggerComponent from './ui-menu/trigger';

export interface UiMenuSignature {
  Element: HTMLHeadingElement;
  Args: EmptyObject;
  Blocks: {
    default: [
      {
        Button: WithBoundArgs<typeof UiMenuButtonComponent, 'close'>;
        Divider: WithBoundArgs<typeof UiMenuDividerComponent, never>;
        Trigger: WithBoundArgs<
          typeof UiMenuTriggerComponent,
          'menu' | 'velcroHook'
        >;
      },
    ];
  };
}

const UiMenuComponent = templateOnlyComponent<UiMenuSignature>();

export default UiMenuComponent;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    UiMenu: typeof UiMenuComponent;
  }
}
