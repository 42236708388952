import UserAdapter from './user';

export default class ViewableTransportPartnerAdapter extends UserAdapter {}

// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/adapter' {
  export default interface AdapterRegistry {
    'viewable-transport-partner': ViewableTransportPartnerAdapter;
  }
}
