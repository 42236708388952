import { template } from "@ember/template-compiler";
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { service } from '@ember/service';
import type { EmptyObject } from '@ember/component/helper';
import type SessionService from '../../services/onward-session';
import type AccountModel from '../../models/account';
import type AccountService from '../../services/account';
import RecentAccountsProvider from '../recent-accounts-provider';
import RecentAccountItem from './recents-list/recent-account-item';
import UiCard from '@onwardcare/ui-components/components/ui-card';
export interface RecentsListSignature {
    Element: HTMLDivElement;
    Args: {
        onSelect: (account: AccountModel) => void;
    };
    Blocks: EmptyObject;
}
export default class RecentsListComponent extends Component<RecentsListSignature> {
    @service
    account: AccountService;
    @service('onward-session')
    session: SessionService;
    get hasNoRecentAccounts() {
        return this.account.recentAccounts.length === 0;
    }
    @action
    handleAccountSelection(account1: AccountModel | null) {
        if (account1 === null) {
            return;
        }
        this.args.onSelect(account1);
    }
    static{
        template(`
    <RecentAccountsProvider as |provider|>
      <UiCard @spacing='none'>
        {{#if provider.isLoading}}
          <div class='px-4 py-2 italic text-gray-500'>
            Loading...
          </div>
        {{else if this.hasNoRecentAccounts}}
          <div class='px-4 py-2 italic text-gray-500'>
            No recently selected accounts.
          </div>
        {{else}}
          <ul class='flex flex-col divide-y'>
            {{#if this.session.hasChangedAccountContext}}
              {{!
                We have to next this instead of combining with the above
                statement in order to make Glint happy.
              }}
              {{#if provider.userAccount}}
                <RecentAccountItem
                  @account={{provider.userAccount}}
                  @onSelect={{this.handleAccountSelection}}
                />
              {{/if}}
            {{/if}}

            {{#each provider.recentAccounts as |account|}}
              <RecentAccountItem
                @account={{account}}
                @onSelect={{this.handleAccountSelection}}
              />
            {{/each}}
          </ul>
        {{/if}}
      </UiCard>
    </RecentAccountsProvider>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
declare module '@glint/environment-ember-loose/registry' {
    export default interface Registry {
        'SelectAccount::RecentsList': typeof RecentsListComponent;
    }
}
