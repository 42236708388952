import Model, { attr, belongsTo } from '@ember-data/model';
import type LanguageModel from './language';

export default class RiderModel extends Model {
  @attr() declare canMakeChanges: boolean;
  @attr() declare cognitiveImpairment: string | null;
  @attr() declare displayName: string;
  // Purposely not using the `Date` type here as the database only stores the
  // date and not the time. If this gets converted to a `Date`, then it will
  // apply the user's timezone to the date and it could be off by a day.
  //
  // For example, "1997-02-05" would end up being converted to
  // "Tue Feb 04 1997 19:00:00 GMT-0500" for a user in the Eastern timezone.
  // Notice that the date is no longer the 5th, but the 4th.
  @attr() declare dob: string | null; // YYYY-MM-DD
  @attr() declare firstName: string;
  @attr() declare gender: string | null;
  @attr() declare hearingImpairment: string | null;
  @attr() declare heightInches: number | null;
  @attr() declare internalNotes: string | null;
  @attr() declare lastName: string;
  @attr() declare mobilityAssistance: string | null;
  @attr() declare notes: string | null;
  @attr() declare privateDriverNotes: string | null;
  @attr() declare profilePhotoUrl: string | null;
  @attr() declare visionImpairment: string | null;
  @attr() declare weightLbs: number | null;

  @belongsTo('language', { async: false, inverse: null })
  declare language: LanguageModel | null;
}

// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    rider: RiderModel;
  }
}
