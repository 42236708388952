import { template } from "@ember/template-compiler";
import type { TOC } from '@ember/component/template-only';
import type { EmptyObject } from '@ember/component/helper';
import BasicColumnChart from '../basic-column-chart';
export interface RideStatusCountsSignature {
    Element: HTMLDivElement;
    Args: EmptyObject;
    Blocks: EmptyObject;
}
const RideStatusCounts: TOC<RideStatusCountsSignature> = template(`
  <BasicColumnChart
    @stacking='percent'
    @modelName='ride-status-count'
    @title='Trip Status'
    @tooltipText='Percent of trips completed, declined, unfulfilled, rider no show, or canceled for trips scheduled with a start date in the date range.'
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default RideStatusCounts;
declare module '@glint/environment-ember-loose/registry' {
    export default interface Registry {
        'Reporting::RideStatusCounts': typeof RideStatusCounts;
    }
}
