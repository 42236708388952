import Controller from '@ember/controller';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { service } from '@ember/service';
import type RouterService from '@ember/routing/router-service';
import type { RequestsGridParams } from '../../../../components/va-tools/requests-grid';
import type { Resolved } from 'types';
import type NewRideRequestsRoute from 'onward-portal/routes/authenticated/va-tools/ride-requests/new';

export default class NewRideRequestsController extends Controller {
  declare model: Resolved<ReturnType<NewRideRequestsRoute['model']>>;

  @service declare router: RouterService;

  queryParams = ['accountId', 'page', 'term'];

  @tracked accountId: string | null = null;
  @tracked page = 1;
  @tracked term = '';
  @tracked isLoading = false;

  get filters() {
    return {
      accountId: this.accountId,
      term: this.term,
    };
  }

  @action
  paramsChanged(params: RequestsGridParams) {
    this.accountId = params.filters?.accountId ?? null;
    this.term = params.filters?.term ?? '';
    this.page = params.page ?? 1;
  }

  @action
  requestClosed() {
    // Refresh the route to update the data because changing the vetride trip
    // status to closed doesn't cause a reload.
    this.router.refresh();
  }
}
