import Controller from '@ember/controller';
import { service } from '@ember/service';
import { action } from '@ember/object';
import type RouterService from '@ember/routing/router-service';
import type AccountModel from '../../models/account';
import type AccountService from '../../services/account';

export default class SelectAccountController extends Controller {
  @service declare account: AccountService;
  @service declare router: RouterService;

  @action
  handleAccountChange(account: AccountModel) {
    this.account.selectAccount(account);
    // TODO: Make sure to update this once we change the default route.
    this.router.transitionTo('authenticated.reporting.index');
  }
}
