import Controller from '@ember/controller';
import type { AdapterResponseError } from 'onward-portal/utils/error-handlers';

export default class ErrorController extends Controller {
  declare model: AdapterResponseError;

  get error() {
    return this.model.errors ? this.model.errors[0] : null;
  }
}
