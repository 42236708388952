import { template } from "@ember/template-compiler";
import type { TOC } from '@ember/component/template-only';
import type { EmptyObject } from '@ember/component/helper';
import BasicColumnChart from '../basic-column-chart';
export interface UncollectedPrivatePayChargeSignature {
    Element: HTMLDivElement;
    Args: EmptyObject;
    Blocks: EmptyObject;
}
const ChartComponent: TOC<UncollectedPrivatePayChargeSignature> = template(`
  <BasicColumnChart
    @format='currency'
    @modelName='uncollected-private-pay-charge'
    @title='Private Pay Uncollected by Trip Date'
    @tooltipText='Total ride charges for trips with a financial responsibility of Private Pay and a start time in the date range where the trip is "Unpaid".'
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ChartComponent;
declare module '@glint/environment-ember-loose/registry' {
    export default interface Registry {
        'Reporting::UncollectedPrivatePayCharge': typeof ChartComponent;
    }
}
