import { template } from "@ember/template-compiler";
import Component from '@glimmer/component';
import type { EmptyObject } from '@ember/component/helper';
import { isPresent } from '@ember/utils';
import { fn } from '@ember/helper';
import { eq } from 'ember-truth-helpers';
import { centsToDollars, formatNumber, inchesToCentimeters, inchesToFeet, leftoverInches, poundsToKilograms } from '../../utils/number-format';
import { dateStringToDateFormat, dateTime, longDateTime } from '@onwardcare/core/utils/date-format';
import DriverCard from './driver-card';
import InfoButton from '../info-button';
import Location from './location';
import RideCompletionStatus from '../ride-completion-status';
import type RideModel from '../../models/ride';
import RideType from '../ride-type';
import RiderCard from './rider-card';
import SectionHeader from './section-header';
import TransportType from '../transport-type';
import UiBadge from '@onwardcare/ui-components/components/ui-badge';
import UiCard from '@onwardcare/ui-components/components/ui-card';
import UiIcon from '@onwardcare/ui-components/components/ui-icon';
import UiMap from '../ui-map';
import UiStarRating from '../ui-star-rating';
export interface RideDetailsSignature {
    Element: HTMLDivElement;
    Args: {
        ride: RideModel;
    };
    Blocks: EmptyObject;
}
const Checkmark = template(`
  <UiIcon @icon='check' @type='micro' class='text-purple-800 inline-block' />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default class RideDetailsComponent extends Component<RideDetailsSignature> {
    get showTransportDetails() {
        const { bariatric: bariatric1, contactPrecautionsRequired: contactPrecautionsRequired1, heightInches: heightInches1, mncs: mncs1, notes: notes1, oxygenRequired: oxygenRequired1, stairsAboveNorm: stairsAboveNorm1, weightLbs: weightLbs1 } = this.args.ride;
        return (bariatric1 || contactPrecautionsRequired1 || isPresent(heightInches1) || mncs1 || isPresent(notes1) || oxygenRequired1 || stairsAboveNorm1 || isPresent(weightLbs1));
    }
    dateTime(date1: Date, timeZone1: string) {
        return dateTime(date1, {
            timeZone: timeZone1
        });
    }
    longDateTime(date1: Date, timeZone1: string) {
        return longDateTime(date1, {
            timeZone: timeZone1
        });
    }
    isoString(date1: Date) {
        return date1.toISOString();
    }
    costCenterDisplay(ride1: RideModel) {
        if (ride1.payerType === ride1.costCenter) {
            return null;
        }
        return ride1.costCenter ? `(${ride1.costCenter})` : null;
    }
    static{
        template(`
    <div class='flex flex-col gap-6'>
      <div class='grid grid-cols-1 gap-8 md:grid-cols-2'>
        <div class='flex flex-col gap-6'>
          <UiCard class='flex flex-col gap-6'>
            <div>
              <div class='flex gap-3 items-baseline'>
                <h2 data-test-id='rider-name' class='text-xl font-semibold'>
                  {{@ride.rider.displayName}}
                </h2>
                {{#if @ride.rider.dob}}
                  <span data-test-id='dob' class='text-sm text-gray-600'>
                    DOB:
                    {{dateStringToDateFormat @ride.rider.dob}}
                  </span>
                {{/if}}
              </div>

              <time
                datetime={{this.isoString @ride.requestedStartTime}}
                data-test-id='requested-start-time'
                class='flex gap-1 items-center'
              >
                {{this.longDateTime
                  @ride.requestedStartTime
                  @ride.account.tzName
                }}

                {{#if @ride.actualStartTime}}
                  <InfoButton>
                    Actual:
                    {{this.dateTime @ride.actualStartTime @ride.account.tzName}}
                  </InfoButton>
                {{/if}}
              </time>
            </div>

            <div class='flex gap-2 items-center'>
              <RideCompletionStatus @status={{@ride.rideCompletionStatus}} />

              <TransportType @transportType={{@ride.transportType}} />

              <RideType @rideType={{@ride.rideType}} class='text-sm' />
            </div>

            <div>
              <div class='text-sm'>
                <span class='text-gray-600'>Coordinator:</span>
                <span data-test-id='custodian-name' class='font-medium'>
                  {{@ride.custodian.displayName}}
                </span>
              </div>
            </div>
          </UiCard>

          <UiCard>
            <div class='flex flex-col gap-3'>
              <div class='flex gap-3 items-start justify-between'>
                <div class='flex flex-col gap-2'>
                  {{#if @ride.actualCostCents}}
                    <div>
                      <span class='text-gray-500'>
                        Charge:
                      </span>
                      <span data-test-id='actual-cost' class='font-medium'>
                        {{#if @ride.suppressPayment}}
                          N/A
                        {{else}}
                          {{centsToDollars @ride.actualCostCents}}
                        {{/if}}
                      </span>
                    </div>
                  {{else if @ride.estimatedCostCents}}
                    <div>
                      <span class='text-gray-500'>Estimate:</span>
                      <span data-test-id='estimated-cost' class='font-medium'>
                        {{#if @ride.suppressPayment}}
                          N/A
                        {{else}}
                          {{centsToDollars @ride.estimatedCostCents}}
                        {{/if}}
                      </span>
                    </div>
                  {{/if}}

                  {{#if @ride.estimatedPayoutCents}}
                    <div class='text-sm'>
                      <span class='text-gray-500'>Payout:</span>
                      <span data-test-id='estimated-payout' class='font-medium'>
                        {{#if @ride.suppressPayment}}
                          N/A
                        {{else}}
                          {{centsToDollars @ride.estimatedPayoutCents}}
                        {{/if}}
                      </span>
                    </div>
                  {{/if}}
                </div>

                <div class='flex gap-4'>
                  {{#if @ride.isInsurance}}
                    <UiBadge data-test-id='insurance' @color='blue'>
                      Insurance
                    </UiBadge>
                  {{/if}}

                  {{#if @ride.juicingBonusCents}}
                    <div class='flex gap-1 items-center'>
                      <span class='text-purple-800'>
                        <UiIcon
                          @icon='currency-dollar'
                          @type='micro'
                          class='inline-block'
                        />
                        <span
                          class='text-xs font-medium uppercase tracking-wider'
                        >
                          Bonus
                        </span>
                      </span>
                      <span data-test-id='juicing-bonus-cost' class='text-sm'>
                        {{centsToDollars @ride.juicingBonusCents}}
                      </span>
                    </div>
                  {{/if}}
                </div>
              </div>

              {{#unless @ride.suppressPayment}}
                {{#if @ride.paymentMethod.id}}
                  <div class='flex gap-2 items-center'>
                    <UiIcon @icon='credit-card' class='text-purple-800' />
                    <span data-test-id='payment-method' class='text-sm'>
                      {{@ride.paymentMethod.name}}
                    </span>
                  </div>
                {{/if}}

                {{#if @ride.account.canBeInvoiced}}
                  <div>
                    <Checkmark />
                    <span data-test-id='invoiced' class='text-sm'>
                      Invoiced
                    </span>
                  </div>
                {{/if}}
              {{/unless}}

              {{#if @ride.payerType}}
                <div class='text-sm'>
                  <span class='text-gray-500'>
                    Financial Responsibility:
                  </span>
                  <span data-test-id='payer-type' class='font-medium'>
                    {{@ride.payerType}}
                  </span>
                  <span data-test-id='cost-center' class='text-gray-500 italic'>
                    {{this.costCenterDisplay @ride}}
                  </span>
                </div>
              {{/if}}
            </div>
          </UiCard>

          {{#if this.showTransportDetails}}
            <UiCard>
              <div class='text-sm flex flex-col gap-3'>
                {{#if @ride.weightLbs}}
                  <div>
                    <span class='text-gray-600'>Weight:</span>
                    <span data-test-id='ride-weight'>
                      <span class='font-medium'>
                        {{@ride.weightLbs}}lbs
                      </span>
                      <span class='text-gray-500 italic'>
                        ({{poundsToKilograms @ride.weightLbs}}kg)
                      </span>
                    </span>
                  </div>
                {{/if}}

                {{#if @ride.heightInches}}
                  <div>
                    <span class='text-gray-600'>Height:</span>
                    <span data-test-id='ride-height'>
                      <span class='font-medium'>
                        {{inchesToFeet @ride.heightInches}}ft
                        {{leftoverInches @ride.heightInches}}in
                      </span>
                      <span class='text-gray-500 italic'>
                        ({{inchesToCentimeters @ride.heightInches}}cm)
                      </span>
                    </span>
                  </div>
                {{/if}}

                {{#if @ride.stairsAboveNorm}}
                  <div>
                    <Checkmark />
                    <span>Has Stairs</span>
                    <span
                      data-test-id='stair-count'
                      class='text-gray-500 italic'
                    >
                      ({{formatNumber @ride.stairsCount}}
                      {{if (eq @ride.stairsCount 1) 'stair' 'stairs'}})
                    </span>
                  </div>
                {{/if}}

                {{#if @ride.bariatric}}
                  <div>
                    <Checkmark />
                    <span data-test-id='bariatric'>
                      Bariatric
                    </span>
                  </div>
                {{/if}}

                {{#if @ride.contactPrecautionsRequired}}
                  <div>
                    <Checkmark />
                    <span data-test-id='contact-precautions-required'>
                      Droplet Precautions Required
                    </span>
                  </div>
                {{/if}}

                {{#if @ride.oxygenRequired}}
                  <div>
                    <Checkmark />
                    <span data-test-id='oxygen-required'>
                      Transport to Provide Oxygen
                    </span>
                  </div>
                {{/if}}

                {{#if @ride.mncs}}
                  <div>
                    <Checkmark />
                    <span data-test-id='mncs'>
                      Medical Necessity Certification Statement
                    </span>
                  </div>
                {{/if}}

                {{#if @ride.notes}}
                  <div>
                    <div class='text-gray-500'>Ride Notes</div>
                    <p data-test-id='ride-notes'>
                      {{@ride.notes}}
                    </p>
                  </div>
                {{/if}}
              </div>
            </UiCard>
          {{/if}}

          {{#let @ride.rideFeedback as |feedback|}}
            {{#if feedback.id}}
              <UiCard>
                <div class='flex flex-col gap-3'>
                  <SectionHeader>Rider Feedback</SectionHeader>

                  {{#if (fn isPresent feedback.stars)}}
                    <UiStarRating @rating={{feedback.stars}} @size='small' />
                  {{/if}}

                  {{#if feedback.driverFeedback}}
                    <p class='text-sm'>{{feedback.driverFeedback}}</p>
                  {{/if}}
                </div>
              </UiCard>
            {{/if}}
          {{/let}}
        </div>

        <div class='flex flex-col gap-4'>
          <div class='flex flex-col gap-4'>
            <Location
              data-test-id='start-location'
              @instructions={{@ride.startLocationInstructions}}
              @location={{@ride.startLocation}}
              @roomNumber={{@ride.pickupRoomNumber}}
            />
            <Location
              data-test-id='end-location'
              @instructions={{@ride.endLocationInstructions}}
              @location={{@ride.endLocation}}
              @roomNumber={{@ride.dropoffRoomNumber}}
            />

            <div class='text-sm text-gray-600 flex gap-1 justify-end'>
              <span data-test-id='passenger-count'>
                {{formatNumber @ride.passengerCount}}
                {{if (eq @ride.passengerCount 1) 'passenger' 'passengers'}},
              </span>

              {{#if @ride.actualDistanceMiles}}
                <div>
                  <span data-test-id='actual-distance'>
                    {{formatNumber @ride.actualDistanceMiles}}
                    {{if (eq @ride.actualDistanceMiles 1) 'mile' 'miles'}}
                  </span>
                </div>
              {{else}}
                <div>
                  <span data-test-id='estimated-distance'>
                    {{formatNumber @ride.estimatedDistanceMiles}}
                    {{if (eq @ride.estimatedDistanceMiles 1) 'mile' 'miles'}}
                  </span>
                </div>
              {{/if}}
            </div>
          </div>

          <div class='h-full min-h-80'>
            <UiMap
              @mapId='ride-details-map'
              @startLocation={{@ride.startLocation}}
              @endLocation={{@ride.endLocation}}
            />
          </div>
        </div>
      </div>

      <div class='grid grid-cols-1 gap-8 md:grid-cols-2 items-start'>
        <UiCard>
          <RiderCard @rider={{@ride.rider}} />
        </UiCard>

        <UiCard>
          <DriverCard @driver={{@ride.driver}} />
        </UiCard>
      </div>
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
declare module '@glint/environment-ember-loose/registry' {
    export default interface Registry {
        'Rides::RideDetails': typeof RideDetailsComponent;
    }
}
