import RESTSerializer from '@ember-data/serializer/rest';
import type Store from '@ember-data/store';
import type { Snapshot } from '@ember-data/store';
import { underscore } from '@ember/string';
import { camelizeObjectKeys } from '../../utils/serialize';

export default class OnwardRESTSerializer extends RESTSerializer {
  keyForAttribute(attr: string) {
    return underscore(attr);
  }

  extractErrors(
    store: Store,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    typeClass: any,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    payload: Record<string, any>,
    id: string | number,
  ) {
    if (payload && payload['httpErrorResponse']) {
      const errorPayload = payload['httpErrorResponse'].payload;

      if (errorPayload.status === 422) {
        /*
          This formats the validation errors coming back from the API to match
          the format that Ember Data expects.

          We get a payload back from the server like this:

          ```
          {
            status: 422,
            success: false,
            message: { name: ["can't be blank"] },
          },
          ```

          And we need to format it like this:

          ```
          {
            name: ["can't be blank"]
          }
          ```

          If the API returns a string for the `message` property instead of an
          object, then we just return the string. Ember Data will ignore it and
          it will not be displayed in the validation error component. This means
          that it wil be handled by the main server error component.
         */
        return errorPayload['message'];
      }
    }

    return payload;
  }

  normalizeArrayResponse(
    store: Store,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    primaryModelClass: any,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    payload: Record<string, any>,
    id: string | number,
    requestType: string,
  ) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const response: Record<string, any> = super.normalizeArrayResponse(
      store,
      primaryModelClass,
      payload,
      id,
      requestType,
    );

    if ('meta' in response) {
      response['meta'] = camelizeObjectKeys(response['meta']);
    }

    return response;
  }

  serializeIntoHash(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    hash: Record<string, any>,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    typeClass: any,
    snapshot: Snapshot,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    options: Record<string, any>,
  ) {
    // This ensures that the root of the payload is always underscored.
    const root = underscore(typeClass.modelName);
    hash[root] = super.serialize(snapshot, options);
  }
}

// DO NOT DELETE: this is how TypeScript knows how to look up your serializers.
declare module 'ember-data/types/registries/serializer' {
  export default interface SerializerRegistry {
    'onward-rest': OnwardRESTSerializer;
  }
}
