import { template } from "@ember/template-compiler";
import Component from '@glimmer/component';
import { service } from '@ember/service';
import { action } from '@ember/object';
import type RouterService from '@ember/routing/router-service';
import type SessionService from '../../services/onward-session';
import type { EmptyObject } from '@ember/component/helper';
import type AccountModel from '../../models/account';
import type AccountService from '../../services/account';
import UiMenu from '../ui-menu';
import UiIcon from '@onwardcare/ui-components/components/ui-icon';
export interface AccountMenuSignature {
    Element: HTMLDivElement;
    Args: EmptyObject;
    Blocks: EmptyObject;
}
export default class AccountMenuComponent extends Component<AccountMenuSignature> {
    @service
    account: AccountService;
    @service
    router: RouterService;
    @service('onward-session')
    session: SessionService;
    get showAccountDropdown() {
        // We are hiding this for Admin users until we add the featuer to allow an
        // Admin user to switch to a non-admin mode.
        return false;
    // return this.session.isAdmin;
    }
    get selectedAccountName() {
        if (!this.session.isAuthenticated || !this.session.user) {
            return null;
        }
        if (this.session.accountContext) {
            return this.session.accountContext.name;
        }
        return this.session.user.accountName;
    }
    @action
    selectAccount(account1?: AccountModel) {
        if (account1 === undefined) {
            this.router.transitionTo('authenticated.select-account');
        } else {
            this.account.selectAccount(account1);
            // TODO: Make sure to update this once we change the default route.
            this.router.transitionTo('authenticated.reporting.index');
        }
    }
    static{
        template(`
    <div data-test-id='account-context'>
      {{#if this.showAccountDropdown}}
        <UiMenu as |menu|>
          <menu.Trigger
            data-test-id='account-context-trigger'
            class='-m-1.5 flex items-center p-1.5 focus:outline-purple-700'
          >
            <span class='hidden lg:flex lg:items-center'>
              {{#if this.session.hasChangedAccountContext}}
                <UiIcon
                  data-test-id='admin'
                  @icon='bolt'
                  @type='micro'
                  class='text-gray-400'
                />
              {{/if}}

              <span
                class='ml-2 text-sm leading-6 text-gray-900'
                aria-hidden='true'
              >
                {{this.selectedAccountName}}
              </span>

              <UiIcon
                @icon='chevron-down'
                @type='mini'
                class='ml-2 text-gray-400'
              />
            </span>
          </menu.Trigger>

          {{!-- Uncomment this once we have an actual landing page for Portal.
          <RecentAccountsProvider as |provider|>
            {{#if provider.isLoading}}
              <menu.Button @disabled={{true}}>
                Loading...
              </menu.Button>
            {{else}}
              {{#if this.session.hasChangedAccountContext}}
                {{!
                  We have to next this instead of combining with the above statement
                  in order to make Glint happy.
                }}
                {{#if provider.userAccount}}
                  <menu.Button
                    @onClick={{fn this.selectAccount provider.userAccount}}
                  >
                    {{provider.userAccount.name}}
                    <span
                      class="inline-flex items-center whitespace-nowrap rounded-md bg-purple-50 px-2 py-1 text-xs font-medium text-purple-800 ring-1 ring-inset ring-purple-700/10"
                    >
                      My Account
                    </span>
                  </menu.Button>
                {{/if}}
              {{/if}}

              {{#each provider.recentAccounts as |account|}}
                <menu.Button @onClick={{fn this.selectAccount account}}>
                  {{account.name}}
                </menu.Button>
              {{/each}}

              {{#if
                (or
                  (and provider.userAccount this.session.hasChangedAccountContext)
                  (gt provider.recentAccounts.length 0)
                )
              }}
                <menu.Divider />
              {{/if}}
            {{/if}}
          </RecentAccountsProvider>
          --}}

          <menu.Button
            data-test-id='select-account'
            @onClick={{this.selectAccount}}
          >
            Select another account
          </menu.Button>
        </UiMenu>
      {{else}}
        <span class='text-sm leading-6 text-gray-900' aria-hidden='true'>
          {{this.selectedAccountName}}
        </span>
      {{/if}}
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
declare module '@glint/environment-ember-loose/registry' {
    export default interface Registry {
        'MainLayout::AccountMenu': typeof AccountMenuComponent;
    }
}
