import Model, { attr, belongsTo } from '@ember-data/model';
import type LanguageModel from './language';

export default class UserLanguageModel extends Model {
  @attr() declare fluency: string;
  @attr() declare isPrimary: boolean;
  @attr() declare userType: string;

  @belongsTo('language', { async: false, inverse: null })
  declare language: LanguageModel;
}

// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'user-language': UserLanguageModel;
  }
}
