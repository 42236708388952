import { template } from "@ember/template-compiler";
import Component from '@glimmer/component';
import type { EmptyObject } from '@ember/component/helper';
import { findAll } from 'ember-data-resources';
// @ts-expect-error - We need to figure out how to create the types for this.
import { sortBy } from 'ember-composable-helpers/helpers/sort-by';
import type PayerTypeModel from '../../models/payer-type';
import type UiFormGroupComponent from '@onwardcare/ui-components/components/ui-form/group';
import { action } from '@ember/object';
const ACCOUNT_OPTION = {
    name: 'Account',
    value: 'None'
};
export interface FinancialResponsibilityFilterSignature {
    Element: HTMLDivElement;
    Args: {
        // TODO: Need to figure out how to properly use the `UiForm` block types.
        form: {
            Group: typeof UiFormGroupComponent;
        };
        financialResponsibilities: string[];
        onChange: (financialResponsibilities: string[]) => unknown;
    };
    Blocks: EmptyObject;
}
export default class FinancialResponsibilityFilterComponent extends Component<FinancialResponsibilityFilterSignature> {
    financialResponsibilitiesData = findAll<PayerTypeModel>(this, 'payer-type');
    get options() {
        let records1 = this.financialResponsibilitiesData.records;
        if (!records1) {
            return [] as PayerTypeModel[];
        }
        const items1 = [
            ...records1.slice(),
            {
                id: '0',
                name: ACCOUNT_OPTION.name
            }
        ];
        return sortBy([
            'name',
            items1
        ]) as PayerTypeModel[];
    }
    get selectedValues() {
        const { financialResponsibilities: financialResponsibilities1 } = this.args;
        return financialResponsibilities1.map((financialResponsibility1)=>{
            if (financialResponsibility1 === ACCOUNT_OPTION.value) {
                return ACCOUNT_OPTION.name;
            }
            return financialResponsibility1;
        });
    }
    get financialResponsibilitiesDisplay() {
        const filteredFinancialResponsibilities1 = this.options.filter((financialResponsibility1)=>{
            return ((financialResponsibility1.name === ACCOUNT_OPTION.name && this.selectedValues.includes(ACCOUNT_OPTION.value)) || this.selectedValues.includes(financialResponsibility1.name));
        });
        return filteredFinancialResponsibilities1 ? filteredFinancialResponsibilities1.map((financialResponsibility1)=>financialResponsibility1.name).join(', ') : null;
    }
    @action
    handleOnChange(financialResponsibilities1: string[]) {
        const updatedItems1 = financialResponsibilities1.map((item1)=>{
            if (item1 === ACCOUNT_OPTION.name) {
                return ACCOUNT_OPTION.value;
            }
            return item1;
        });
        this.args.onChange(updatedItems1);
    }
    static{
        template(`
    <@form.Group data-test-id='financial-responsibilities' as |Group|>
      <Group.Label>Financial Responsibilities</Group.Label>
      <Group.Listbox
        @multiple={{true}}
        @value={{this.selectedValues}}
        @onChange={{this.handleOnChange}}
        as |listbox|
      >
        <listbox.Button @placeholder='All Financial Responsibilities'>
          {{this.financialResponsibilitiesDisplay}}
        </listbox.Button>

        {{#each this.options as |financialResponsibility|}}
          <listbox.Option @value={{financialResponsibility.name}}>
            {{financialResponsibility.name}}
          </listbox.Option>
        {{else}}
          <listbox.Empty>
            There are no Financial Responsibilities.
          </listbox.Empty>
        {{/each}}
      </Group.Listbox>
    </@form.Group>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
declare module '@glint/environment-ember-loose/registry' {
    export default interface Registry {
        'Filters::FinancialResponsibilityFilter': typeof FinancialResponsibilityFilterComponent;
    }
}
