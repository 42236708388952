import { template } from "@ember/template-compiler";
import type { TOC } from '@ember/component/template-only';
import type { EmptyObject } from '@ember/component/helper';
import BasicColumnChart from '../basic-column-chart';
export interface TotalChargeAndPaymentSignature {
    Element: HTMLDivElement;
    Args: EmptyObject;
    Blocks: EmptyObject;
}
const TotalChargeAndPayment: TOC<TotalChargeAndPaymentSignature> = template(`
  <BasicColumnChart
    @format='currency'
    @modelName='total-charge-and-payment'
    @title='Total Ride Charges and Total Ride Payouts'
    @tooltipText='Sum of ride charges and ride payouts for trips with a ride charge or payout within the date range.'
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default TotalChargeAndPayment;
declare module '@glint/environment-ember-loose/registry' {
    export default interface Registry {
        'Reporting::TotalChargeAndPayment': typeof TotalChargeAndPayment;
    }
}
