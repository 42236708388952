import { template } from "@ember/template-compiler";
import type { TOC } from '@ember/component/template-only';
export interface ChartSectionSignature {
    Element: HTMLDivElement;
    Args: {
        fullWidth?: boolean;
    };
    Blocks: {
        title: [];
        default: [];
    };
}
const ChartSectionComponent: TOC<ChartSectionSignature> = template(`
  <div class='mt-8 sm:mt-10 lg:mt-14'>
    {{#if (has-block 'title')}}
      <h3 class='text-xl font-semibold text-gray-900 lg:text-2xl'>
        {{yield to='title'}}
      </h3>

      <hr class='mt-2 border-t' />
    {{/if}}

    <div
      class='{{if (has-block "title") "mt-4 lg:mt-6"}}
        grid grid-cols-1 gap-4 sm:gap-6 lg:gap-8
        {{unless @fullWidth "2xl:grid-cols-2"}}'
    >
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ChartSectionComponent;
declare module '@glint/environment-ember-loose/registry' {
    export default interface Registry {
        ChartSection: typeof ChartSectionComponent;
    }
}
