export type RideTypeId = 'delivery' | 'oneway' | 'roundtrip';

export type RideType = {
  id: RideTypeId;
  name: string;
};

const rideTypes: RideType[] = [
  {
    id: 'delivery',
    name: 'Delivery',
  },
  {
    id: 'oneway',
    name: 'One Way',
  },
  {
    id: 'roundtrip',
    name: 'Round Trip',
  },
];

export default rideTypes;
