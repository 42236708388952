import Route from '@ember/routing/route';
import { service } from '@ember/service';
import type SessionService from '../../../services/onward-session';
import type RouterService from '@ember/routing/router-service';
import type Transition from '@ember/routing/transition';

export default class OperationsRoute extends Route {
  @service declare router: RouterService;
  @service('onward-session') declare session: SessionService;

  async beforeModel(transition: Transition) {
    if (this.session.isOnwardAssociate === false) {
      return this.router.replaceWith('access-denied');
    }
  }
}
