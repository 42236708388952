import { camelize } from '@ember/string';

/**
 * Converts the keys of an object to camel case.
 *
 * @param object The object to camelize the keys of.
 * @returns Returns a new object with the keys camelized.
 */
export function camelizeObjectKeys(object: Record<string, unknown>) {
  const updatedQuery: typeof object = {};

  Object.keys(object).forEach((key) => {
    updatedQuery[camelize(key)] = object[key];
  });

  return updatedQuery;
}

/**
 * Converts a string array to a comma separated string.
 *
 * Example: ['a', 'b', 'c'] => 'a,b,c'
 *
 * @param array An array of strings.
 * @returns Returns a string of comma separated values.
 */
export function arrayToString(array: string[]) {
  return array.join(',');
}
