import Service from '@ember/service';

export interface Setting {
  name: string;
  value: string;
}

export default class SettingsService extends Service {
  _settings: Setting[] = [];

  /**
   * Retrieves the name of the environment.
   *
   * @returns Returns the environment name.
   */
  getEnvironment(): 'localhost' | 'dev' | 'staging' | 'production' {
    // This is sort of a hack to figure out which environment we're in. It would
    // be good to find a way to read this from an environment variable, but
    // since promoting an app to a new environment in Heroku doesn't do a build,
    // it will use the same environment variables as the previous environment,
    // which would always be Dev.
    const url = window.location.host;

    if (
      url.startsWith('onward-portal-production.') ||
      url.startsWith('portal.')
    ) {
      return 'production';
    }

    if (
      url.startsWith('onward-portal-staging.') ||
      url.startsWith('staging-portal.')
    ) {
      return 'staging';
    }

    if (url.startsWith('onward-portal-dev.') || url.startsWith('dev-portal.')) {
      return 'dev';
    }

    if (url.startsWith('localhost')) {
      return 'localhost';
    }

    throw Error('Unable to determine the environment');
  }

  /**
   * Loads the application settings.
   */
  async load() {
    const environment = this.getEnvironment();
    // The timestamp is used to prevent the browser from caching the file.
    const response = await fetch(
      `/settings-${environment}.json?t=${new Date().getTime()}`,
    );
    const settingsResponse = await response.json();

    if (response.ok) {
      settingsResponse.settings.forEach((setting: Setting) => {
        this._settings.push(setting);
      });

      return Promise.resolve();
    } else {
      return Promise.reject({
        name: 'Error',
        message: 'Failed to load the application settings',
      });
    }
  }

  /**
   * Retrieves the value for a given setting name.
   *
   * @param name The setting name.
   * @returns Returns the value of the setting and `undefined` if the setting
   * doesn't exist..
   */
  getValue(name: Setting['name']) {
    const setting = this._settings.find((setting) => setting.name === name);
    return setting?.value;
  }
}

// Don't remove this declaration: this is what enables TypeScript to resolve
// this service using `Owner.lookup('service:settings')`, as well
// as to check when you pass the service name as an argument to the decorator,
// like `@service('settings') declare altName: SettingsService;`.
declare module '@ember/service' {
  interface Registry {
    settings: SettingsService;
  }
}
