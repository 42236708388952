import { template } from "@ember/template-compiler";
import Component from '@glimmer/component';
import type { EmptyObject } from '@ember/component/helper';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { service } from '@ember/service';
import type Store from '@ember-data/store';
import { restartableTask } from 'ember-concurrency';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore - We need to figure out how to create the types for this.
import sortBy from 'ember-composable-helpers/helpers/sort-by';
import type UiFormGroupComponent from '@onwardcare/ui-components/components/ui-form/group';
import type GeoModel from '../../models/geo';
import type RegionModel from '../../models/region';
import type ZipcodeModel from '../../models/zipcode';
import { arrayToString } from '../../utils/serialize';
export type Location = {
    geoId: string | null;
    regionIds: string[];
    zipcodeIds: string[];
};
export interface LocationFilterSignature {
    Element: HTMLDivElement;
    Args: {
        // TODO: Need to figure out how to properly use the `UiForm` block types.
        form: {
            Group: typeof UiFormGroupComponent;
        };
        geoId: string | null;
        regionIds: string[];
        zipcodeIds: string[];
        onChange: (location: Location) => unknown;
    };
    Blocks: EmptyObject;
}
export default class LocationFilterComponent extends Component<LocationFilterSignature> {
    @service
    store: Store;
    @tracked
    geosData: GeoModel[] = [];
    @tracked
    regionsData: RegionModel[] = [];
    @tracked
    zipcodesData: ZipcodeModel[] = [];
    get geo() {
        const { geoId: geoId1 } = this.args;
        if (geoId1) {
            const geo1 = this.geosData.find((geo1)=>geo1.id === geoId1);
            if (geo1) {
                return geo1;
            }
        }
        return null;
    }
    get regions() {
        const { regionIds: regionIds1 } = this.args;
        if (regionIds1) {
            return this.regionsData.filter((region1)=>regionIds1.includes(region1.id));
        }
        return [];
    }
    get zipcodes() {
        const { zipcodeIds: zipcodeIds1 } = this.args;
        if (zipcodeIds1) {
            return this.zipcodesData.filter((zipcode1)=>zipcodeIds1.includes(zipcode1.id));
        }
        return [];
    }
    get regionsDisplay() {
        return this.regions.map((region1)=>region1.name).join(', ');
    }
    get zipcodesDisplay() {
        return this.zipcodes.map((zipcode1)=>zipcode1.number).join(', ');
    }
    get groupingClasses() {
        return `border-l-4 ${this.geo ? 'border-purple-500' : 'border-transparent'} pl-3 -ml-4`;
    }
    constructor(owner1: unknown, args1: LocationFilterSignature['Args']){
        super(owner1, args1);
        this.fetchGeos.perform();
        this.fetchRegions.perform();
        this.fetchZipcodes.perform();
    }
    fetchGeos = restartableTask(async ()=>{
        const geos1 = await this.store.findAll('geo');
        this.geosData = geos1.slice();
    });
    fetchRegions = restartableTask(async ()=>{
        const { geoId: geoId1 } = this.args;
        const regions1 = geoId1 ? await this.store.query('region', {
            filter: {
                geo_id: this.args.geoId
            }
        }) : [];
        this.regionsData = regions1.slice();
    });
    fetchZipcodes = restartableTask(async ()=>{
        const { regionIds: regionIds1 } = this.args;
        const zipcodes1 = regionIds1.length > 0 ? await this.store.query('zipcode', {
            filter: {
                region_id: arrayToString(this.args.regionIds)
            }
        }) : [];
        this.zipcodesData = zipcodes1.slice();
    });
    @action
    geoSelected(geo1: GeoModel | null) {
        const location1: Location = {
            geoId: geo1?.id || null,
            regionIds: [],
            zipcodeIds: []
        };
        this.args.onChange(location1);
        this.fetchRegions.perform();
    }
    @action
    regionsSelected(regions1: RegionModel[]) {
        const { geoId: geoId1 } = this.args;
        const location1: Location = {
            geoId: geoId1,
            regionIds: regions1.map((region1)=>region1.id),
            zipcodeIds: []
        };
        this.args.onChange(location1);
        this.fetchZipcodes.perform();
    }
    @action
    zipcodesSelected(zipcodes1: ZipcodeModel[]) {
        const { geoId: geoId1, regionIds: regionIds1 } = this.args;
        const location1: Location = {
            geoId: geoId1,
            regionIds: regionIds1,
            zipcodeIds: zipcodes1.map((zipcode1)=>zipcode1.id)
        };
        this.args.onChange(location1);
    }
    static{
        template(`
    <div class='transition flex flex-col gap-2 {{this.groupingClasses}}'>
      <@form.Group data-test-id='geo' as |Group|>
        <Group.Label>Geo</Group.Label>
        <Group.Listbox
          @allowClear={{true}}
          @value={{this.geo}}
          @onChange={{this.geoSelected}}
          as |listbox|
        >
          <listbox.Button @placeholder='All Geos'>
            {{this.geo.displayName}}
          </listbox.Button>

          {{!
          TODO: Need to figure out why the items aren't returning in the
                correct order even though the API is returning them sorted.
        }}
          {{#each (sortBy 'displayName' this.geosData) as |geo|}}
            <listbox.Option @value={{geo}}>
              {{geo.displayName}}
            </listbox.Option>
          {{else}}
            <listbox.Empty>
              There are no Geos.
            </listbox.Empty>
          {{/each}}
        </Group.Listbox>
        <Group.HelpText>
          This filters on start location.
        </Group.HelpText>
      </@form.Group>

      {{#if this.geo}}
        <@form.Group data-test-id='region' as |Group|>
          <Group.Label>Region</Group.Label>
          <Group.Listbox
            @multiple={{true}}
            @value={{this.regions}}
            @onChange={{this.regionsSelected}}
            as |listbox|
          >
            <listbox.Button @placeholder='All Regions'>
              {{this.regionsDisplay}}
            </listbox.Button>

            {{!
            TODO: Need to figure out why the items aren't returning in the
                  correct order even though the API is returning them sorted.
          }}
            {{#each (sortBy 'name' this.regionsData) as |region|}}
              <listbox.Option @value={{region}}>
                {{region.name}}
              </listbox.Option>
            {{else}}
              <listbox.Empty>
                There are no Regions.
              </listbox.Empty>
            {{/each}}
          </Group.Listbox>
          <Group.HelpText>
            This filters on start location.
          </Group.HelpText>
        </@form.Group>
      {{/if}}

      {{#if this.regions}}
        <@form.Group data-test-id='zipcode' as |Group|>
          <Group.Label>Zipcode</Group.Label>
          <Group.Listbox
            @multiple={{true}}
            @value={{this.zipcodes}}
            @onChange={{this.zipcodesSelected}}
            as |listbox|
          >
            <listbox.Button @placeholder='All Zipcodes'>
              {{this.zipcodesDisplay}}
            </listbox.Button>

            {{!
            TODO: Need to figure out why the items aren't returning in the
                  correct order even though the API is returning them sorted.
          }}
            {{#each (sortBy 'number' this.zipcodesData) as |zipcode|}}
              <listbox.Option @value={{zipcode}}>
                {{zipcode.number}}
              </listbox.Option>
            {{else}}
              <listbox.Empty>
                There are no Zipcodes.
              </listbox.Empty>
            {{/each}}
          </Group.Listbox>
          <Group.HelpText>
            This filters on start location.
          </Group.HelpText>
        </@form.Group>
      {{/if}}
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
declare module '@glint/environment-ember-loose/registry' {
    export default interface Registry {
        'Filters::LocationFilter': typeof LocationFilterComponent;
    }
}
