// Tailwind CSS classes
const PURPLE_700 = '#7e22ce';
const SKY_500 = '#0ea5e9';
const RED_600 = '#dc2626';
const GREEN_500 = '#22c55e';
const ORANGE_500 = '#f97316';
const GRAY_950 = '#030712';
const GRAY_500 = '#6b7280';
const GRAY_300 = '#d1d5db';
const TEXT_XS = '.75rem';

export const chartSettings = {
  axisLineColor: GRAY_500,
  axisTextColor: GRAY_950,
  axisTextSize: TEXT_XS,
  axisTickColor: GRAY_500,
  dataLabelColor: GRAY_950,
  dataLabelSize: TEXT_XS,
  gridLineColor: GRAY_300,
  seriesColors: [PURPLE_700, SKY_500, RED_600, GREEN_500, ORANGE_500],
};
