import Route from '@ember/routing/route';
import type Transition from '@ember/routing/transition';
import { service } from '@ember/service';
import { all } from 'ember-concurrency';
import type AccountService from '../services/account';
import type SessionService from '../services/onward-session';
import type RouterService from '@ember/routing/router-service';

export default class AuthenticatedRoute extends Route {
  @service declare account: AccountService;
  @service('onward-session') declare session: SessionService;
  @service declare router: RouterService;

  async beforeModel(transition: Transition) {
    this.session.requireAuthentication(transition, 'sign-in');

    if (this.session.isAuthenticated) {
      // If going to the root authenticated route, redirect to the default module.
      if (transition.to?.name === 'authenticated.index') {
        return this.router.replaceWith('authenticated.reporting.index');
      }

      await this.session.loadUser.perform();

      return all([
        this.account.fetchRecentAccounts.perform(),
        this.account.fetchUserAccount.perform(),
      ]);
    }
  }
}
