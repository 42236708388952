import ReportingAdapter from './namespaces/reporting';

export default class VolumeByDayOfWeekAdapter extends ReportingAdapter {}

// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/adapter' {
  export default interface AdapterRegistry {
    'volume-by-day-of-week': VolumeByDayOfWeekAdapter;
  }
}
