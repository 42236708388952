import OnwardJSONAPISerializer from './shared/onward-jsonapi';

export default class VehicleSerializer extends OnwardJSONAPISerializer {}

// DO NOT DELETE: this is how TypeScript knows how to look up your serializers.
declare module 'ember-data/types/registries/serializer' {
  export default interface SerializerRegistry {
    vehicle: VehicleSerializer;
  }
}
