/* import __COLOCATED_TEMPLATE__ from './mobile-navigation.hbs'; */
import Component from '@glimmer/component';
import { service } from '@ember/service';
import type RouterService from '@ember/routing/router-service';

export interface MobileNavigationSignature {
  Element: HTMLDivElement;
  Args: {
    isOpen: boolean;
    closeMenu: (event?: Event) => void;
  };
  Blocks: {
    default: [];
  };
}

export default class MobileNavigation extends Component<MobileNavigationSignature> {
  @service declare router: RouterService;

  constructor(owner: unknown, args: MobileNavigationSignature['Args']) {
    super(owner, args);

    // Automatically close the mobile navigation menu when the route changes.
    this.router.on('routeDidChange', () => {
      this.args.closeMenu();
    });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'MainLayout::MobileNavigation': typeof MobileNavigation;
  }
}
