import Component from '@glimmer/component';
import Highcharts from 'highcharts';

export default class UiChartComponent<T> extends Component<T> {
  // There is no way to define this as the global Highcharts object.
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  protected highcharts: any | null = null;
  protected chart: Highcharts.Chart | null = null;

  async loadHighcharts() {
    if (!this.highcharts) {
      // This will lazy load the Highcharts library. There seems to be an issue
      // with this and deploying the package with ember-cli-addon-docs. When it
      // loads the JavaScript module, it isn't replacing the ADDON_DOCS_ROOT_URL
      // with the actual URL, so the docs get a 404 error trying to load it.
      //
      // For now, we'll just import it directly. If this does work at some
      // point, make sure to remove the import above.
      //
      //const { default: Highcharts } = await import('highcharts');
      //this.highcharts = Highcharts;

      this.highcharts = Highcharts;
    }
  }
}
