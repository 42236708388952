import ApplicationSerializer from './application';
import type Store from '@ember-data/store';
import { camelizeObjectKeys } from '../utils/serialize';

export default class VetrideTripSerializer extends ApplicationSerializer {
  normalizeResponse(
    store: Store,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    primaryModelClass: any,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    payload: Record<string, any>,
    id: string | number,
    requestType: string,
  ) {
    const response = super.normalizeResponse(
      store,
      primaryModelClass,
      payload,
      id,
      requestType,
    );

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore - Figure out how to make this type work.
    if (Array.isArray(response.data)) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore - Figure out how to make this type work.
      response.data.forEach((item) => {
        if (item.attributes.changesDiff) {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore - Figure out how to make this type work.
          item.attributes.changesDiff = camelizeObjectKeys(
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore - Figure out how to make this type work.
            item.attributes.changesDiff,
          );
        }
      });
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore - Figure out how to make this type work.
    } else if (response.data.attributes.changesDiff) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore - Figure out how to make this type work.
      response.data.attributes.changesDiff = camelizeObjectKeys(
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore - Figure out how to make this type work.
        response.data.attributes.changesDiff,
      );
    }

    return response;
  }
}

// DO NOT DELETE: this is how TypeScript knows how to look up your serializers.
declare module 'ember-data/types/registries/serializer' {
  export default interface SerializerRegistry {
    'vetride-trip': VetrideTripSerializer;
  }
}
