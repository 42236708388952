import { template } from "@ember/template-compiler";
import Component from '@glimmer/component';
import type { EmptyObject } from '@ember/component/helper';
import { service } from '@ember/service';
import type SettingsService from '../services/settings';
export interface UiAvatarSignature {
    Element: HTMLHeadingElement;
    Args: {
        photoUrl: string | null | undefined;
    };
    Blocks: EmptyObject;
}
export default class UiAvatarComponent extends Component<UiAvatarSignature> {
    @service
    settings: SettingsService;
    get apiUrl() {
        return this.settings.getValue('API_URL');
    }
    get photoUrl() {
        const { photoUrl: photoUrl1 } = this.args;
        return photoUrl1 ? new URL(photoUrl1, this.apiUrl).toString() : null;
    }
    static{
        template(`
    <div data-test-id='avatar' class='shrink-0'>
      {{#if this.photoUrl}}
        <img
          data-test-id='avatar-photo'
          class='inline-block h-14 w-14 rounded-full object-cover'
          src={{this.photoUrl}}
          alt=''
        />
      {{else}}
        <span
          data-test-id='avatar-placeholder'
          class='inline-block h-14 w-14 overflow-hidden rounded-full bg-gray-100'
        >
          <svg
            class='h-full w-full text-gray-300'
            fill='currentColor'
            viewBox='0 0 24 24'
          >
            <path
              d='M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z'
            />
          </svg>
        </span>
      {{/if}}
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
declare module '@glint/environment-ember-loose/registry' {
    export default interface Registry {
        UiAvatar: typeof UiAvatarComponent;
    }
}
