import ApplicationAdapter from '../application';
import { vaToolsNamespace } from '../../utils/adapter-namespaces';

export default class VaToolsAdapter extends ApplicationAdapter {
  namespace = vaToolsNamespace;
}

// DO NOT DELETE: this is how TypeScript knows how to look up your adapters.
declare module 'ember-data/types/registries/adapter' {
  export default interface AdapterRegistry {
    'va-tools': VaToolsAdapter;
  }
}
