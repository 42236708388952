import templateOnlyComponent from '@ember/component/template-only';
import type { ComponentLike } from '@glint/template';

type MenuOption = {
  active: boolean;
  disabled: boolean;
  selected: boolean;
};

interface MenuOptionSignature {
  Element: HTMLLIElement;
  Args: {
    value: string | number | object;
    disabled?: boolean;
  };
  Blocks: {
    default: [option: MenuOption];
  };
}

export interface UiComboboxOptionSignature {
  Element: HTMLElement;
  Args: {
    value: string | number | object;
    disabled?: boolean;
    optionComponent: ComponentLike<MenuOptionSignature>;
  };
  Blocks: {
    default: [option: MenuOption];
  };
}

const UiComboboxOptionComponent =
  templateOnlyComponent<UiComboboxOptionSignature>();

export default UiComboboxOptionComponent;
