import { template } from "@ember/template-compiler";
import Component from '@glimmer/component';
import type { EmptyObject } from '@ember/component/helper';
import { action } from '@ember/object';
import InfoButton from '../info-button';
import UiForm from '@onwardcare/ui-components/components/ui-form';
import UiSearch from '../ui-search';
export interface SearchSignature {
    Element: HTMLFormElement;
    Args: {
        placeholder?: string;
        term: string;
        onChange: (term: string) => void;
    };
    Blocks: EmptyObject;
}
export default class SearchComponent extends Component<SearchSignature> {
    get placeholder() {
        return this.args.placeholder ?? 'Search Rides...';
    }
    @action
    handleSearchClear() {
        this.args.onChange('');
    }
    @action
    handleSearch(event1: SubmitEvent) {
        if (event1.target instanceof HTMLFormElement) {
            const data1 = new FormData(event1.target);
            const term1 = data1.get('rides-search') as string;
            this.args.onChange(term1);
        }
    }
    static{
        template(`
    <UiForm
      data-test-id='rides-search-form'
      @noStyles={{true}}
      @onSubmit={{this.handleSearch}}
      as |Form|
    >
      <Form.Group data-test-id='rides-search' as |Group|>
        <Group.Label for='rides-search' class='sr-only'>
          Search Rides
        </Group.Label>
        <div class='flex items-center w-64'>
          <UiSearch
            placeholder={{this.placeholder}}
            id='rides-search'
            name='rides-search'
            @term={{@term}}
            @onClear={{this.handleSearchClear}}
          />
          <InfoButton>
            Search by Ride ID or ID or name of Custodian, Rider, and Driver.
          </InfoButton>
        </div>
      </Form.Group>
    </UiForm>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
declare module '@glint/environment-ember-loose/registry' {
    export default interface Registry {
        'Rides::Search': typeof SearchComponent;
    }
}
