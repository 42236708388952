/* global Highcharts */ import { template } from "@ember/template-compiler";
import Component from '@glimmer/component';
import { service } from '@ember/service';
import { queryRecord } from 'ember-data-resources';
import type { EmptyObject } from '@ember/component/helper';
import type RideCountModel from '../../models/ride-count';
import { type ChartParams, partialOrFutureDateXAxisFormatter, partialOrFutureDateXAxisTitleOptions } from '../../utils/chart';
import UiColumnChart from '@onwardcare/ui-components/components/ui-column-chart';
import type { ChartDateCategory } from '@onwardcare/ui-components/types';
import UiLineChart from '@onwardcare/ui-components/components/ui-line-chart';
import type ReportingFiltersService from '../../services/reporting-filters';
import UiCard from '@onwardcare/ui-components/components/ui-card';
import InfoButton from '../info-button';
import ChartContainer from '../chart-container';
import { eq } from 'ember-truth-helpers';
export interface OverallRidesSignature {
    Element: HTMLDivElement;
    Args: {
        chartType: 'column' | 'line';
        transportTypeCategory: 'companion' | 'referred' | 'overall';
    };
    Blocks: EmptyObject;
}
interface OverallRidesChartParams extends ChartParams {
    transportTypeCategory?: OverallRidesSignature['Args']['transportTypeCategory'];
}
export default class OverallRidesComponent extends Component<OverallRidesSignature> {
    @service
    reportingFilters: ReportingFiltersService;
    overallRidesData = queryRecord<RideCountModel>(this, 'ride-count', ()=>this.chartParams);
    get chartParams() {
        const params1: OverallRidesChartParams = this.reportingFilters.chartParams;
        if (this.args.transportTypeCategory === 'overall') {
            return params1;
        }
        params1.transportTypeCategory = this.args.transportTypeCategory;
        return params1;
    }
    get categories() {
        return this.overallRidesData.record?.categories ? this.overallRidesData.record.categories : [];
    }
    get series() {
        return this.overallRidesData.record?.series ?? [];
    }
    get isEmpty() {
        // This handles the use case where the `series` comes back as an empty
        // array.
        //
        // Example:
        // series: []
        if (this.series.length === 0) {
            return true;
        }
        // This handles the use case where the the `y` value is "0" for all data
        // arrays in the series.
        //
        // Example:
        // series: [{
        //   name: "Comparion",
        //   data: [{ y: 0 }, { y: 0 }, { y: 0 }]
        // }, {
        //   name: "Rideshare",
        //   data: [{ y: 0 }, { y: 0 }, { y: 0 }]
        // }]
        return this.series.every((series1)=>{
            return series1.data.every((data1)=>data1.y === 0);
        });
    }
    get xAxisTitle() {
        const containsPartial1 = this.categories.some((category1)=>category1.isPartial);
        return containsPartial1 ? partialOrFutureDateXAxisTitleOptions(this.chartParams.dateGrouping) : undefined;
    }
    xAxisFormatter(context1: Highcharts.AxisLabelsFormatterContextObject) {
        const category1 = this.categories[context1.pos] as ChartDateCategory;
        return partialOrFutureDateXAxisFormatter(category1.isPartial, context1.value);
    }
    static{
        template(`
    <UiCard @spacing='tight'>
      <h2
        class='mb-4 text-center text-lg font-semibold text-gray-900 lg:text-xl'
      >
        {{#if (eq @transportTypeCategory 'companion')}}
          Trending Companion Rides

          <InfoButton class='text-gray-900'>
            Count of companion trips completed or no showed trips trended by
            account.
          </InfoButton>
        {{else if (eq @transportTypeCategory 'referred')}}
          Trending Referred Rides

          <InfoButton class='text-gray-900'>
            Count of Referred trips (e.g. Wheelchair, Stretcher, Uber/Lyft, or
            Ambulance) completed or no showed trips trended by account.
          </InfoButton>
        {{else}}
          Overall Completed Rides

          <InfoButton class='text-gray-900'>
            Count of both companion and referred trips completed within the Date
            Range by customer or customer segment (e.g. B2C).
          </InfoButton>
        {{/if}}
      </h2>

      {{! This would be a w-104 height in TW, but that doesn't exist }}
      {{#let 'h-[416px]' as |heightClasses|}}
        <ChartContainer
          class={{heightClasses}}
          @dataResource={{this.overallRidesData}}
          @isEmpty={{this.isEmpty}}
        >
          {{#if (eq @chartType 'line')}}
            <UiLineChart
              class={{heightClasses}}
              @data={{this.series}}
              @categories={{this.categories}}
              @xAxisFormatter={{this.xAxisFormatter}}
              @xAxisTitle={{this.xAxisTitle}}
            />
          {{else}}
            <UiColumnChart
              class={{heightClasses}}
              @data={{this.series}}
              @categories={{this.categories}}
              @stacking='normal'
              @xAxisFormatter={{this.xAxisFormatter}}
              @xAxisTitle={{this.xAxisTitle}}
            />
          {{/if}}
        </ChartContainer>
      {{/let}}
    </UiCard>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
declare module '@glint/environment-ember-loose/registry' {
    export default interface Registry {
        'Reporting::OverallRides': typeof OverallRidesComponent;
    }
}
