import { template } from "@ember/template-compiler";
import type { TOC } from '@ember/component/template-only';
import type { EmptyObject } from '@ember/component/helper';
export interface UiPageTitleSignature {
    Element: HTMLHeadingElement;
    Args: EmptyObject;
    Blocks: {
        default: [];
    };
}
const UiPageTitle: TOC<UiPageTitleSignature> = template(`
  <h1 class='text-2xl font-semibold text-gray-900 lg:text-3xl'>
    {{yield}}
  </h1>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default UiPageTitle;
declare module '@glint/environment-ember-loose/registry' {
    export default interface Registry {
        UiPageTitle: typeof UiPageTitle;
    }
}
