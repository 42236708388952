import Controller from '@ember/controller';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import type { RequestsGridParams } from '../../../../components/va-tools/requests-grid';
import type { Resolved } from 'types';
import type ClosedRideRequestsRoute from 'onward-portal/routes/authenticated/va-tools/ride-requests/closed';

export default class ClosedRideRequestsController extends Controller {
  declare model: Resolved<ReturnType<ClosedRideRequestsRoute['model']>>;

  queryParams = ['accountId', 'page', 'rideCompletionStatuses', 'term'];

  @tracked accountId: string | null = null;
  @tracked page = 1;
  @tracked rideCompletionStatuses: string[] = [];
  @tracked term = '';
  @tracked isLoading = false;

  get filters() {
    return {
      accountId: this.accountId,
      hasChanges: false,
      rideCompletionStatuses: this.rideCompletionStatuses,
      term: this.term,
    };
  }

  @action
  paramsChanged(params: RequestsGridParams) {
    this.page = params.page ?? 1;
    this.accountId = params.filters?.accountId ?? null;
    this.rideCompletionStatuses = params.filters?.rideCompletionStatuses ?? [];
    this.term = params.filters?.term ?? '';
  }
}
