import {
  addMinutes,
  differenceInMinutes,
  differenceInYears,
  isValid,
  set,
} from 'date-fns';
import { format, formatInTimeZone, toDate } from 'date-fns-tz';
// Since we support both `date-fns` v2 and v3, we needed to extract the
// `toZonedTime` from `date-fns-tz` v3 because it was renamed from
// `utcToZonedTime` in v2. There was no way to support both versions without
// some super nasty stuff, so while we support v2, we copied the code here.
import { toZonedTime } from './_date-fns-lib/to-zoned-time';

export const DATE_PICKER_FORMAT = 'MM/dd/yyyy';

const DATE_FORMAT = 'M/dd/yyyy';
const ISO_DATE_FORMAT = 'yyyy-MM-dd';
const LONG_DATE_FORMAT = 'EEEE, MMMM do, yyyy';
const MEDIUM_DATE_FORMAT = 'MMM d, yyyy';

const HOUR_FORMAT_24 = 'HH:mm';
const HOUR_FORMAT_12 = 'h:mmaaa';

/**
 * The available options for formatting a date.
 */
interface DateFormatOptions {
  /**
   * Whether to hide the time zone when formatting the time. Defaults to `false`.
   */
  hideTimeZone?: boolean;
  /**
   * The text to render if the date is `null` or `undefined`. Defaults to an empty string.
   */
  nullText?: string;
  /**
   * The time format to use when formatting the date. Defaults to 12-hour format.
   */
  timeFormat?: '12' | '24';
  /**
   * The time zone to use when formatting the date. Defaults the the local time zone of the device.
   */
  timeZone?: string | null;
}

const DEFAULT_OPTIONS: DateFormatOptions = {
  hideTimeZone: false,
  nullText: '',
  timeFormat: '12',
  timeZone: undefined,
};

function _getOptions(options?: DateFormatOptions) {
  // We need to convert the `null` for `timeZone` to `undefined`.
  if (options && options.timeZone === null) {
    options = { ...options, timeZone: undefined };
  }

  return { ...DEFAULT_OPTIONS, ...options };
}

function _getNullText(options: DateFormatOptions) {
  return options.nullText || '';
}

function _formatInTimeZone(
  date: Date,
  dateFormat: string,
  options: DateFormatOptions,
) {
  if (options.timeZone) {
    return formatInTimeZone(date, options.timeZone, dateFormat);
  }

  return format(date, dateFormat);
}

function _ensureDate(date: Date | string | null | undefined) {
  if (typeof date === 'string') {
    return toDate(date);
  }

  return date;
}

function _getTimezoneDifference(
  date: Date,
  timeZone1: string,
  timeZone2: string,
) {
  const zonedDate1 = toZonedTime(date, timeZone1);
  const zonedDate2 = toZonedTime(date, timeZone2);

  return differenceInMinutes(zonedDate1, zonedDate2);
}

/**
 * Gets the local time zone of the device.
 *
 * @returns Returns the local time zone.
 */
export function localTimeZone() {
  return Intl.DateTimeFormat().resolvedOptions().timeZone;
}

/**
 * Converts a date that is in the local time zone to the provided time zone,
 * then returns the date in ISO string format.
 *
 * This is most useful when taking user entered input where you want to take
 * the exact date and time they entered, but that needs to be done in the
 * context of another time zone.
 *
 * For example, if the user is in the Eastern time zone and they are working in
 * an app that wants everything to be done in the Pacific time zone, then the
 * date and time they enter should be Pacific. `Date` objects are always in the
 * local time zone, so we need to first take what they entered and convert it
 * to the provided time zone, then create an ISO string so it's in UTC.
 *
 * @param date The date to convert to ISO string.
 * @param timeZone The time zone to convert to. Defaults to the local time zone if not provided.
 * @returns Returns the date in ISO string format.
 */
export function convertLocalToISOInTimeZone(date: Date, timeZone?: string) {
  // Because the provided time is in the local time zone, we need to get the
  // difference in minutes between the local time zone and the provided time
  // zone so that we can manipulate the date.
  const minutesDiff = _getTimezoneDifference(
    date,
    localTimeZone(),
    timeZone ?? localTimeZone(),
  );

  return addMinutes(date, minutesDiff).toISOString();
}

/**
 * Formats the date and time in the long format.
 *
 * Example: Monday, January 1st, 2024 12:30pm PST
 *
 * @param date The date to format.
 * @param options The options to use when formatting the date.
 * @returns The formatted date string.
 */
export function longDateTime(
  date: Date | string | null | undefined,
  options?: DateFormatOptions,
) {
  const _date = _ensureDate(date);
  const _options = _getOptions(options);

  if (_date) {
    return `${_longDateOnly(_date, _options)} ${_timeOnly(_date, _options)}`;
  }

  return _getNullText(_options);
}

/**
 * Formats the date and time in the medium format.
 *
 * Example: Jan 1, 2024 12:30pm PST
 *
 * @param date The date to format.
 * @param options The options to use when formatting the date.
 * @returns The formatted date string.
 */
export function mediumDateTime(
  date: Date | string | null | undefined,
  options?: DateFormatOptions,
) {
  const _date = _ensureDate(date);
  const _options = _getOptions(options);

  if (_date) {
    return `${_mediumDateOnly(_date, _options)} ${_timeOnly(_date, _options)}`;
  }

  return _getNullText(_options);
}

/**
 * Formats the date and time in the short format.
 *
 * Example: 1/01/2024 12:30pm PST
 *
 * @param date The date to format.
 * @param options The options to use when formatting the date.
 * @returns The formatted date string.
 */
export function dateTime(
  date: Date | string | null | undefined,
  options?: DateFormatOptions,
) {
  const _date = _ensureDate(date);
  const _options = _getOptions(options);

  if (date) {
    return `${_dateOnly(_date, _options)} ${_timeOnly(_date, _options)}`;
  }

  return _getNullText(_options);
}

/**
 * Formats the date only in the long format.
 *
 * Example: Monday, January 1st, 2024
 *
 * @param date The date to format.
 * @param options The options to use when formatting the date.
 * @returns The formatted date string.
 */
export function longDateOnly(
  date: Date | string | null | undefined,
  options?: DateFormatOptions,
) {
  const _date = _ensureDate(date);
  const _options = _getOptions(options);

  return _longDateOnly(_date, _options);
}

function _longDateOnly(
  date: Date | null | undefined,
  options: DateFormatOptions,
) {
  if (date) {
    return _formatInTimeZone(date, LONG_DATE_FORMAT, options);
  }

  return _getNullText(options);
}

/**
 * Formats the date only in the medium format.
 *
 * Example: Jan 1, 2024
 *
 * @param date The date to format.
 * @param options The options to use when formatting the date.
 * @returns The formatted date string.
 */
export function mediumDateOnly(
  date: Date | string | null | undefined,
  options?: DateFormatOptions,
) {
  const _date = _ensureDate(date);
  const _options = _getOptions(options);

  return _mediumDateOnly(_date, _options);
}

function _mediumDateOnly(
  date: Date | null | undefined,
  options: DateFormatOptions,
) {
  if (date) {
    return _formatInTimeZone(date, MEDIUM_DATE_FORMAT, options);
  }

  return _getNullText(options);
}

/**
 * Formats the date only in the short format.
 *
 * Example: 1/01/2024
 *
 * @param date The date to format.
 * @param options The options to use when formatting the date.
 * @returns The formatted date string.
 */
export function dateOnly(
  date: Date | string | null | undefined,
  options?: DateFormatOptions,
) {
  const _date = _ensureDate(date);
  return _dateOnly(_date, _getOptions(options));
}

function _dateOnly(date: Date | null | undefined, options: DateFormatOptions) {
  if (date) {
    return _formatInTimeZone(date, DATE_FORMAT, options);
  }

  return _getNullText(options);
}

/**
 * Formats the time only.
 *
 * Example: 12:30pm PST
 *
 * @param date The date to format.
 * @param options The options to use when formatting the date.
 * @returns The formatted time string.
 */
export function timeOnly(
  date: Date | string | null | undefined,
  options?: DateFormatOptions,
) {
  const _date = _ensureDate(date);
  const _options = _getOptions(options);

  return _timeOnly(_date, _options);
}

function _timeOnly(
  date: Date | null | undefined,
  options: DateFormatOptions,
  hideTimeZone?: boolean,
) {
  if (date) {
    let timeFormat =
      options.timeFormat === '24' ? HOUR_FORMAT_24 : HOUR_FORMAT_12;

    if (hideTimeZone !== true && options.hideTimeZone !== true) {
      timeFormat = `${timeFormat} z`;
    }

    return _formatInTimeZone(date, timeFormat, options);
  }

  return _getNullText(options);
}

/**
 * Formats a time range.
 *
 * Example: 12:30pm - 1:45pm PST
 *
 * @param startDate The starting date to format.
 * @param endDate The ending date to format.
 * @param options The options to use when formatting the date.
 * @returns The formatted time range string. If the `endDate` is `null` or `undefined`, then only the `startDate` will be returned.
 */
export function timeRange(
  startDate: Date | string | null | undefined,
  endDate: Date | string | null | undefined,
  options?: DateFormatOptions,
) {
  const _startDate = _ensureDate(startDate);
  const _endDate = _ensureDate(endDate);
  const _options = _getOptions(options);

  if (_startDate && _endDate) {
    return `${_timeOnly(_startDate, _options, true)} - ${_timeOnly(_endDate, _options)}`;
  }

  if (_startDate) {
    return _timeOnly(_startDate, _options);
  }

  return _getNullText(_options);
}

/**
 * Formats a date string like "YYYY-MM-DD" to "MM/DD/YYYY". This is mainly for the date only fields from the database like `dob`.
 *
 * @param dateString The date string to format. Should be in the format of "YYYY-MM-DD".
 * @param options The options to use when formatting the date.
 * @returns The formatted date string.
 */
export function dateStringToDateFormat(
  dateString: string | null | undefined,
  options?: DateFormatOptions,
) {
  const _options = _getOptions(options);

  if (dateString) {
    const date = toDate(dateString);
    return _dateOnly(date, _options);
  }

  return _getNullText(_options);
}

/**
 * Formats the a date string to an ISO string.
 *
 * Example: 08/27/2024 => 2024-08-27
 *
 * @param date The date string to format.
 * @returns Returns the date of birth string formatted as an ISO string. If the date was invalid, `null` will be returned.
 */
export function dateStringToISO(date: string) {
  const dateObj = new Date(date);

  if (isValid(dateObj) === false) {
    return null;
  }

  return format(dateObj, ISO_DATE_FORMAT);
}

/**
 * Returns the timezone.
 *
 * Example: PST
 *
 * @param date The date to format.
 * @param options The options to use when formatting the date.
 * @returns Returns the timezone.
 */
export function timeZone(
  date: Date | null | undefined,
  options?: DateFormatOptions,
) {
  const _options = _getOptions(options);
  return _timeZone(date, _options);
}

function _timeZone(date: Date | null | undefined, options: DateFormatOptions) {
  if (date) {
    return _formatInTimeZone(date, 'z', options);
  }

  return _getNullText(options);
}

/**
 * Formats a date string like "YYYY-MM-DD" to the age of the person.
 *
 * @param dateString The date string to format. Should be in the format of "YYYY-MM-DD".
 * @returns The age of the person based on the date of birth.
 */
export function ageFromDob(dateString: string | null | undefined) {
  if (dateString) {
    const now = new Date();
    const dob = toDate(dateString);

    return differenceInYears(now, dob);
  }

  return null;
}

/**
 * Takes in a time string and returns an array with the hours and minutes as numbers.
 *
 * @param time Time as a string in a 24-hour format.
 * @returns Returns an array with the hours and minutes as numbers.
 */
function _timeParts(time: string) {
  const parts = time.split(':');
  return [Number(parts[0]), Number(parts[1])];
}

/**
 * Sets the time on a date object.
 *
 * @param date The date to set the time on.
 * @param time Time as a string in a 24-hour format.
 * @returns Returns a new date with the time set to the provided time.
 */
export function updateDateWithTimeString(date: Date, time: string) {
  const [hours, minutes] = _timeParts(time);
  return set(date, { hours, minutes, seconds: 0, milliseconds: 0 });
}
