import Modifier from 'ember-modifier';
import type {
  ChartDateCategory,
  ColumnSeries,
  LineSeries,
  PieSeries,
} from '../types.ts';

interface ChartData {
  categories?: ChartDateCategory[] | string[];
  data: ColumnSeries[] | LineSeries[] | PieSeries[];
}

type NamedArguments = {
  chartData: ChartData;
  render: (element: HTMLElement) => Promise<void> | void;
  update: () => Promise<void> | void;
};

export interface InitChartModifierSignature {
  Element: HTMLDivElement;
  Args: {
    Positional: [];
    Named: NamedArguments;
  };
}

export default class InitChartModifier extends Modifier<InitChartModifierSignature> {
  firstRender = true;

  modify(
    element: HTMLDivElement,
    positional: [],
    { chartData, render, update }: NamedArguments,
  ) {
    if (this.firstRender) {
      this.firstRender = false;
      render(element);
    } else {
      update();
    }
  }
}
