import Route from '@ember/routing/route';
import { action } from '@ember/object';
import { service } from '@ember/service';
import type Store from '@ember-data/store';

export default class RideDetailsRoute extends Route {
  @service declare store: Store;

  model({ id }: { id: string }) {
    return this.store.findRecord('ride', id, {
      include:
        'account.parent_account,custodian,driver.current_vehicle,driver.transport_partner,driver.user_languages.language,driver.veteran_status,end_location,payment_method,ride_feedback,rider.language,start_location,transport_type',
      // This will make sure that the model is reloaded when the route is
      // entered so we don't get any janky UI state when the additional items
      // are being loaded.
      reload: true,
    });
  }

  @action
  activate() {
    // Makes sure that the page gets scrolled to the top when changing routes.
    window.scrollTo(0, 0);
  }
}
