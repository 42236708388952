import ApplicationAdapter from './application';
import { pluralize } from 'ember-inflector';
import { underscore } from '@ember/string';

export default class UserAdapter extends ApplicationAdapter {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore: Need to figure out how to properly type this, but `modelName`
  // should be one of the models in the ModelRegistry.
  urlForQuery(
    query: Record<string, string | number | boolean>,
    modelName: string,
  ) {
    if (modelName === 'user') {
      return super.urlForQuery(query, modelName);
    }

    const id = query['id'];

    // We want to remove the `id` from the query params since we are applying it
    // to the URL.
    delete query['id'];

    return `${this.host}/${this.namespace}/users/${id}/${pluralize(
      underscore(modelName),
    )}`;
  }

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore: Need to figure out how to properly type this, but `modelName`
  // should be one of the models in the ModelRegistry.
  urlForQueryRecord(
    query: Record<string, string | number | boolean>,
    modelName: string,
  ) {
    if (modelName === 'user') {
      return super.urlForQueryRecord(query, modelName);
    }

    const id = query['id'];

    // We want to remove the `id` from the query params since we are applying it
    // to the URL.
    delete query['id'];

    return `${this.host}/${this.namespace}/users/${id}/${pluralize(
      underscore(modelName),
    )}`;
  }
}

// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/adapter' {
  export default interface AdapterRegistry {
    user: UserAdapter;
  }
}
