import Route from '@ember/routing/route';
import { service } from '@ember/service';
import type SessionService from '../services/onward-session';

export default class SignInRoute extends Route {
  @service('onward-session') declare session: SessionService;

  beforeModel() {
    this.session.prohibitAuthentication('authenticated');
  }
}
