import UserAdapter from './user';

export default class ViewableAccountAdapter extends UserAdapter {}

// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/adapter' {
  export default interface AdapterRegistry {
    'viewable-account': ViewableAccountAdapter;
  }
}
