import VectorcareAdapter from './namespaces/vectorcare';

export default class VectorcareServiceLevelAdapter extends VectorcareAdapter {}

// DO NOT DELETE: this is how TypeScript knows how to look up your adapters.
declare module 'ember-data/types/registries/adapter' {
  export default interface AdapterRegistry {
    'vectorcare-service-level': VectorcareServiceLevelAdapter;
  }
}
