import ReportingAdapter from './namespaces/reporting';

export default class PaymentsByRideStatusAdapter extends ReportingAdapter {}

// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/adapter' {
  export default interface AdapterRegistry {
    'payments-by-ride-status': PaymentsByRideStatusAdapter;
  }
}
