import Model, { attr, belongsTo } from '@ember-data/model';
import type AccountModel from './account';
import type CustodianModel from './custodian';
import type DriverModel from './driver';
import type PaymentMethodModel from './payment-method';
import type { RideCompletionStatus } from 'onward-portal/utils/data/ride-completion-status';
import type RideFeedbackModel from './ride-feedback';
import type RiderModel from './rider';
import type { RideTypeId } from 'onward-portal/utils/data/ride-types';
import type TransportTypeModel from './transport-type';
import type LocationModel from './location';

export default class RideModel extends Model {
  @attr() declare actualCostCents: number;
  @attr() declare actualDistanceMiles: number | null;
  @attr('date') declare actualStartTime: Date | null;
  @attr() declare bariatric: boolean;
  @attr() declare contactPrecautionsRequired: boolean;
  @attr() declare costCenter: string | null;
  @attr('date') declare createdAt: Date;
  @attr() declare dropoffRoomNumber: string | null;
  @attr() declare endLocationInstructions: string | null;
  @attr() declare estimatedCostCents: number;
  @attr() declare estimatedDistanceMiles: number | null;
  @attr() declare estimatedPayoutCents: number;
  @attr() declare heightInches: number | null;
  @attr() declare isInsurance: boolean;
  @attr() declare juicingBonusCents: number;
  @attr() declare mncs: boolean;
  @attr() declare notes: string | null;
  @attr() declare oxygenRequired: boolean;
  @attr() declare passengerCount: number;
  @attr() declare payerType: string | null;
  @attr() declare pickupRoomNumber: string | null;
  @attr('date') declare requestedStartTime: Date;
  @attr() declare rideCompletionStatus: RideCompletionStatus['id'];
  @attr() declare rideType: RideTypeId;
  @attr() declare stairsAboveNorm: boolean;
  @attr() declare startLocationInstructions: string | null;
  @attr() declare stairsCount: number | null;
  @attr() declare suppressPayment: boolean;
  @attr() declare weightLbs: number | null;

  @belongsTo('account', { async: false, inverse: null })
  declare account: AccountModel;
  @belongsTo('custodian', { async: false, inverse: null })
  declare custodian: CustodianModel;
  @belongsTo('driver', { async: false, inverse: null })
  declare driver: DriverModel | null;
  @belongsTo('location', { async: false, inverse: null })
  declare endLocation: LocationModel;
  @belongsTo('payment-method', { async: false, inverse: null })
  declare paymentMethod: PaymentMethodModel;
  @belongsTo('ride-feedback', { async: false, inverse: null })
  declare rideFeedback: RideFeedbackModel;
  @belongsTo('rider', { async: false, inverse: null })
  declare rider: RiderModel;
  @belongsTo('location', { async: false, inverse: null })
  declare startLocation: LocationModel;
  @belongsTo('transport-type', { async: false, inverse: null })
  declare transportType: TransportTypeModel;
}

// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    ride: RideModel;
  }
}
