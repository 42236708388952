import { template } from "@ember/template-compiler";
import type { TOC } from '@ember/component/template-only';
import type { EmptyObject } from '@ember/component/helper';
const SectionHeader: TOC<{
    Element: HTMLHeadingElement;
    Args: EmptyObject;
    Blocks: {
        default: [];
    };
}> = template(`
  <h3 class='font-medium' ...attributes>{{yield}}</h3>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SectionHeader;
declare module '@glint/environment-ember-loose/registry' {
    export default interface Registry {
        'Rides::SectionHeader': typeof SectionHeader;
    }
}
