import { template } from "@ember/template-compiler";
import Component from '@glimmer/component';
import type { EmptyObject } from '@ember/component/helper';
import UiIcon from '@onwardcare/ui-components/components/ui-icon';
const DEFAULT_STAR_COUNT = 5;
export interface UiStarRatingSignature {
    Element: HTMLDivElement;
    Args: {
        size?: 'default' | 'small';
        starCount?: number;
        rating: number | null;
    };
    Blocks: EmptyObject;
}
export default class UiStarRatingComponent extends Component<UiStarRatingSignature> {
    get starCount() {
        const { starCount: starCount1 } = this.args;
        return starCount1 !== undefined && starCount1 > 0 ? starCount1 : DEFAULT_STAR_COUNT;
    }
    get stars() {
        const { rating: rating1 } = this.args;
        if (rating1 === null) {
            return [];
        }
        const stars1 = new Array(this.starCount).fill(0);
        return stars1.map((_1, index1)=>{
            return {
                id: index1 + 1,
                fill: index1 < rating1
            };
        });
    }
    get iconSizeClass() {
        return this.args.size === 'small' ? 'w-4 h-4' : '';
    }
    get textSizeClass() {
        return this.args.size === 'small' ? 'text-xs' : 'text-sm';
    }
    static{
        template(`
    {{#if this.stars}}
      <div class='flex items-center gap-2'>
        <div data-test-id='star-rating' class='flex gap-1 items-center'>
          {{#each this.stars as |star|}}
            <UiIcon
              data-test-id='star'
              data-test-type={{if star.fill 'solid' 'outline'}}
              @icon='star'
              @type={{if star.fill 'solid' 'outline'}}
              class='text-purple-800 {{this.iconSizeClass}}'
            />
          {{/each}}
        </div>
        {{! Need the 2px top margin to perfectly align the text with the icons. }}
        <span
          data-test-id='star-rating-text'
          class='inline-block mt-[2px] text-gray-500 {{this.textSizeClass}}'
        >
          ({{@rating}}
          of
          {{this.starCount}})
        </span>
      </div>
    {{/if}}
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
declare module '@glint/environment-ember-loose/registry' {
    export default interface Registry {
        UiStarRating: typeof UiStarRatingComponent;
    }
}
