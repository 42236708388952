import type Store from '@ember-data/store';
import { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import Session from 'ember-simple-auth/services/session';
import type { OnwardToken } from '../authenticators/onward';
import { all, restartableTask } from 'ember-concurrency';
import type User from '../models/user';
import type AccountModel from '../models/account';
import type ViewableAccountModel from '../models/viewable-account';
import type ViewableTransportPartnerModel from '../models/viewable-transport-partner';
import type ViewableTransportTypeModel from '../models/viewable-transport-type';
import type RouterService from '@ember/routing/router-service';
import ROLES from '../utils/data/user-roles';

export default class OnwardSessionService extends Session<OnwardToken> {
  @service declare router: RouterService;
  // We have to use a different name than `store` here because the base Session
  // service already has a `store` service that's not Ember's store service.
  @service('store') declare emberStore: Store;

  @tracked user: User | null = null;
  @tracked accountContext: AccountModel | null = null;
  @tracked timeZone: string = this.systemTimeZone;
  @tracked viewableAccounts: ViewableAccountModel[] = [];
  @tracked viewableTransportPartners: ViewableTransportPartnerModel[] = [];
  @tracked viewableTransportTypes: ViewableTransportTypeModel[] = [];

  /**
   * The time zone of the user's system.
   */
  get systemTimeZone() {
    return Intl.DateTimeFormat().resolvedOptions().timeZone;
  }

  /**
   * Whether the user is mimicking a different account context than their own.
   * This is an ability of an admin so that they can use the app as if they were
   * in a different account.
   */
  get hasChangedAccountContext() {
    return this.accountContext !== null;
  }

  /**
   * The user's named roles.
   */
  get roles() {
    return this.user?.roles ?? [];
  }

  /**
   * Whether the user is a super admin.
   */
  get isSuperAdmin() {
    return this.hasRole(ROLES.SUPER_ADMIN);
  }

  /**
   * Whether the user is an admin.
   */
  get isAdmin() {
    return this.hasRole(ROLES.ADMIN);
  }

  /**
   * Whether the user is an Onward associate.
   */
  get isOnwardAssociate() {
    // We only assign the "Admin" and "Super Admin" roles to Onward associates,
    // so we can check for "Admin", "Super Admin", and "Onward Associate" roles
    // here.
    return (
      this.hasRole(ROLES.ONWARD_ASSOCIATE) || this.isAdmin || this.isSuperAdmin
    );
  }

  /**
   * Checks if the user has the given role.
   *
   * @param role The named user role to check for.
   * @returns Returns true if the user has the given role, false otherwise.
   */
  hasRole(role: string) {
    return this.roles.includes(role);
  }

  /**
   * Checks if the user has all the given roles.
   *
   * @param roles The named user roles to check for.
   * @returns Returns true if the user has all the given roles, false otherwise.
   */
  hasRoles(roles: string[]) {
    return roles.every((role) => this.hasRole(role));
  }

  /**
   * Checks if the user does not have the given role.
   *
   * @param role The named user role to check for.
   * @returns Returns true if the user does not have the given role, false otherwise.
   */
  doesNotHaveRole(role: string) {
    return this.hasRole(role) === false;
  }

  loadUser = restartableTask(async () => {
    try {
      const userId = this.data.authenticated.id;

      // If there is no user id in the auth token, we need to sign the user out
      // so that they can properly sign in. This could happen if they have a
      // really old session before we put the user id in the token.
      if (userId === undefined) {
        await this.invalidate();
        return this.router.transitionTo('sign-in');
      }

      this.user = await this.emberStore.findRecord('user', userId, {
        // We need to make sure we don't use a cached version of this
        reload: true,
      });
    } catch (ex: any) /* eslint-disable-line @typescript-eslint/no-explicit-any */ {
      // If the user doesn't exist, we need to sign the user out so that they
      // can properly sign in. This likely won't happen in production, but it
      // can in lower environments where the cookies are shared.
      if (ex.errors?.[0]?.status === '404') {
        await this.invalidate();
        return this.router.transitionTo('sign-in');
      }
    }
  });

  loadAccountData = restartableTask(async () => {
    let requests = [
      this._fetchViewableTransportPartners.perform(),
      this._fetchViewableTransportTypes.perform(),
    ];

    if (this.isAdmin === false) {
      requests = [...requests, this._fetchViewableAccounts.perform()];
    }

    await all(requests);
  });

  _fetchViewableAccounts = restartableTask(async () => {
    if (this.user === null) {
      this.viewableAccounts = [];
      return;
    }

    const accounts = await this.emberStore.query('viewable-account', {
      id: this.user.id,
    });

    this.viewableAccounts = accounts.slice();
  });

  _fetchViewableTransportPartners = restartableTask(async () => {
    if (this.user === null) {
      this.viewableTransportPartners = [];
      return;
    }

    const transportPartners = await this.emberStore.query(
      'viewable-transport-partner',
      {
        id: this.user?.id,
      },
    );

    this.viewableTransportPartners = transportPartners.slice();
  });

  _fetchViewableTransportTypes = restartableTask(async () => {
    if (this.user === null) {
      this.viewableTransportTypes = [];
      return;
    }

    const transportTypes = await this.emberStore.query(
      'viewable-transport-type',
      {
        id: this.user?.id,
      },
    );

    this.viewableTransportTypes = transportTypes.slice();
  });
}

// Don't remove this declaration: this is what enables TypeScript to resolve
// this service using `Owner.lookup('service:onward-session')`, as well
// as to check when you pass the service name as an argument to the decorator,
// like `@service('onward-session') declare altName: OnwardSessionService;`.
declare module '@ember/service' {
  interface Registry {
    'onward-session': OnwardSessionService;
  }
}
