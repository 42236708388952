import { template } from "@ember/template-compiler";
import type { TOC } from '@ember/component/template-only';
import type { EmptyObject } from '@ember/component/helper';
import UiIcon from '@onwardcare/ui-components/components/ui-icon';
export interface PageLoaderSignature {
    Element: HTMLDivElement;
    Args: EmptyObject;
    Blocks: EmptyObject;
}
const PageLoader: TOC<PageLoaderSignature> = template(`
  <div class='h-full flex justify-center items-center'>
    <span class='sr-only'>Loading...</span>
    <UiIcon class='h-12 w-12 text-gray-800' @icon='spinner' @spin={{true}} />
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default PageLoader;
declare module '@glint/environment-ember-loose/registry' {
    export default interface Registry {
        PageLoader: typeof PageLoader;
    }
}
