import { template } from "@ember/template-compiler";
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import type { EmptyObject } from '@ember/component/helper';
import { service } from '@ember/service';
import type AccountModel from '../../models/account';
import { restartableTask, timeout } from 'ember-concurrency';
import type Store from '@ember-data/store';
import set from 'ember-set-helper/helpers/set';
import perform from 'ember-concurrency/helpers/perform';
import UiForm from '@onwardcare/ui-components/components/ui-form';
const MIN_SEARCH_CHARACTER = 3;
const DEBOUNCE_MS = 400;
export interface AccountFormSignature {
    Element: HTMLDivElement;
    Args: {
        onChange: (account: AccountModel) => void;
    };
    Blocks: EmptyObject;
}
export default class AccountFormComponent extends Component<AccountFormSignature> {
    @service
    store: Store;
    @tracked
    selectedAccount: AccountModel | null = null;
    @tracked
    accounts: AccountModel[] = [];
    @action
    accountDisplayValue(value1: AccountModel | string | null) {
        if (typeof value1 === 'object' && value1 !== null) {
            return value1.name;
        }
        return value1 ?? '';
    }
    fetchAccounts = restartableTask(async (term1?: string)=>{
        if (term1 && term1.length >= MIN_SEARCH_CHARACTER) {
            const accounts1 = await this.store.query('account', {
                filter: {
                    name: {
                        match: term1
                    }
                }
            });
            this.accounts = accounts1.slice();
        } else {
            this.accounts = [];
        }
    });
    accountFilterChanged = restartableTask(async (term1: string)=>{
        if (term1.length < MIN_SEARCH_CHARACTER) {
            this.accounts = [];
            return;
        }
        await timeout(DEBOUNCE_MS);
        this.fetchAccounts.perform(term1);
    });
    @action
    handleAccountChange() {
        if (this.selectedAccount) {
            this.args.onChange?.(this.selectedAccount);
        }
    }
    static{
        template(`
    <UiForm @onSubmit={{this.handleAccountChange}} as |Form|>
      <Form.Group data-test-id='account' as |Group|>
        <Group.Label>Account</Group.Label>
        <Group.Combobox
          @placeholder='Enter at least 3 characters to search'
          @value={{this.selectedAccount}}
          @allowClear={{true}}
          @displayValue={{this.accountDisplayValue}}
          @onChange={{set this 'selectedAccount'}}
          @onFilterChange={{perform this.accountFilterChanged}}
          as |combobox|
        >
          {{#if this.fetchAccounts.isRunning}}
            <combobox.Option @value='' @disabled={{true}}>
              Loading...
            </combobox.Option>
          {{else}}
            {{#each this.accounts as |account|}}
              <combobox.Option @value={{account}}>
                {{account.name}}
              </combobox.Option>
            {{else}}
              <combobox.Empty>
                No accounts found.
              </combobox.Empty>
            {{/each}}
          {{/if}}
        </Group.Combobox>
      </Form.Group>

      <Form.Actions as |Actions|>
        <Actions.Submit data-test-id='select-button'>
          Select
        </Actions.Submit>
      </Form.Actions>
    </UiForm>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
declare module '@glint/environment-ember-loose/registry' {
    export default interface Registry {
        'SelectAccount::AccountForm': typeof AccountFormComponent;
    }
}
