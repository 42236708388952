import DefaultChartModel from './shared/default-chart';

export default class DriverReviewPercentageModel extends DefaultChartModel {}

// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'driver-review-percentage': DriverReviewPercentageModel;
  }
}
