import Model, { attr } from '@ember-data/model';
import type {
  ChartDateCategory,
  ColumnSeries,
} from '@onwardcare/ui-components/types';

export default class DefaultChartModel extends Model {
  @attr() declare categories: ChartDateCategory[];
  @attr() declare series: ColumnSeries[];
}

// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'default-chart': DefaultChartModel;
  }
}
