import { template } from "@ember/template-compiler";
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { on } from '@ember/modifier';
export interface UiMenuButtonSignature {
    Element: HTMLButtonElement | HTMLAnchorElement;
    Args: {
        close?: () => void;
        disabled?: boolean;
        href?: string;
        onClick?: () => void;
    };
    Blocks: {
        default: [];
    };
}
export default class UiMenuButtonComponent extends Component<UiMenuButtonSignature> {
    get classes() {
        const baseClassess1 = 'block w-full px-3 py-1 text-left text-sm leading-6 text-gray-900';
        const stateClasses1 = this.args.disabled ? 'cursor-not-allowed opacity-50' : 'hover:bg-gray-100 focus:bg-gray-100 focus:outline-purple-700';
        return `${baseClassess1} ${stateClasses1}`;
    }
    @action
    handleClick() {
        this.args.onClick?.();
        this.args.close?.();
    }
    static{
        template(`
    {{#let
      'block w-full px-3 py-1 text-left text-sm leading-6 text-gray-900 hover:bg-gray-100 focus:bg-gray-100 focus:outline-purple-700'
      as |classes|
    }}
      {{#if @href}}
        <a
          href={{@href}}
          class={{classes}}
          ...attributes
          {{on 'click' this.handleClick}}
        >
          {{yield}}
        </a>
      {{else}}
        <button
          type='button'
          class={{classes}}
          ...attributes
          {{on 'click' this.handleClick}}
        >
          {{yield}}
        </button>
      {{/if}}
    {{/let}}
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
declare module '@glint/environment-ember-loose/registry' {
    export default interface Registry {
        'ui-menu/button': typeof UiMenuButtonComponent;
        'UiMenu::Button': typeof UiMenuButtonComponent;
    }
}
