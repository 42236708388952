export type Environment = 'localhost' | 'dev' | 'staging' | 'production';

export function getEnvironment(): Environment {
  // This is sort of a hack to figure out which environment we're in. It would
  // be good to find a way to read this from an environment variable, but since
  // promoting an app to a new environment in Heroku doesn't do a build, it will
  // use the same environment variables as the previous environment, which would
  // always be Dev.
  const url = window.location.host;

  if (
    url.startsWith('onward-portal-production.') ||
    url.startsWith('portal.onwardrides')
  ) {
    return 'production';
  }

  if (
    url.startsWith('onward-portal-staging.') ||
    url.startsWith('staging-portal.')
  ) {
    return 'staging';
  }

  if (url.startsWith('onward-portal-dev.') || url.startsWith('dev-portal.')) {
    return 'dev';
  }

  if (url.startsWith('localhost') || url.includes('192')) {
    return 'localhost';
  }

  throw Error('Unable to determine the environment');
}
